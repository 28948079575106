'use strict';

// Array of country objects for the flag dropdown.
// Each contains a name, country code (ISO 3166-1 alpha-2) and dial code.

// Originally from https://github.com/mledoze/countries
// then with a couple of manual re-arrangements to be alphabetical
// then changed Kazakhstan from +76 to +7
// and Vatican City from +379 to +39 (see issue 50)
// and Caribean Netherlands from +5997 to +599
// and Curacao from +5999 to +599
// Removed:  Kosovo, Pitcairn Islands, South Georgia

// LAST UPDATE [v4.0.1] - 2020-12-24
// We changed the 'cca2' field name (in the original repo) to 'iso2' in ours
// keep this in mind if updating this file with a newer version.

/* exported
  getCountries
 */

function getCountries() {
// jshint ignore: start
  var allCountries = [
    {
      "name": {
        "common": "Aruba",
        "official": "Aruba",
        "native": {
          "nld": {
            "official": "Aruba",
            "common": "Aruba"
          },
          "pap": {
            "official": "Aruba",
            "common": "Aruba"
          }
        }
      },
      "iso2": "AW",
      "ccn3": "533",
      "cca3": "ABW",
      "cioc": "ARU",
      "currencies": {
        "AWG": {
          "name": "Aruban florin",
          "symbol": "ƒ"
        }
      },
      "capital": [
        "Oranjestad"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "nld": "Dutch",
        "pap": "Papiamento"
      },
      "latlng": [
        12.5,
        -69.96666666
      ],
      "flag": "🇦🇼",
      "area": 180
    },
    {
      "name": {
        "common": "Afghanistan",
        "official": "Islamic Republic of Afghanistan",
        "native": {
          "prs": {
            "official": "جمهوری اسلامی افغانستان",
            "common": "افغانستان"
          },
          "pus": {
            "official": "د افغانستان اسلامي جمهوریت",
            "common": "افغانستان"
          },
          "tuk": {
            "official": "Owganystan Yslam Respublikasy",
            "common": "Owganystan"
          }
        }
      },
      "iso2": "AF",
      "ccn3": "004",
      "cca3": "AFG",
      "cioc": "AFG",
      "currencies": {
        "AFN": {
          "name": "Afghan afghani",
          "symbol": "؋"
        }
      },
      "capital": [
        "Kabul"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "prs": "Dari",
        "pus": "Pashto",
        "tuk": "Turkmen"
      },
      "latlng": [
        33,
        65
      ],
      "flag": "🇦🇫",
      "area": 652230
    },
    {
      "name": {
        "common": "Angola",
        "official": "Republic of Angola",
        "native": {
          "por": {
            "official": "República de Angola",
            "common": "Angola"
          }
        }
      },
      "iso2": "AO",
      "ccn3": "024",
      "cca3": "AGO",
      "cioc": "ANG",
      "currencies": {
        "AOA": {
          "name": "Angolan kwanza",
          "symbol": "Kz"
        }
      },
      "capital": [
        "Luanda"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "por": "Portuguese"
      },
      "latlng": [
        -12.5,
        18.5
      ],
      "flag": "🇦🇴",
      "area": 1246700
    },
    {
      "name": {
        "common": "Anguilla",
        "official": "Anguilla",
        "native": {
          "eng": {
            "official": "Anguilla",
            "common": "Anguilla"
          }
        }
      },
      "iso2": "AI",
      "ccn3": "660",
      "cca3": "AIA",
      "cioc": "",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "The Valley"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        18.25,
        -63.16666666
      ],
      "flag": "🇦🇮",
      "area": 91
    },
    {
      "name": {
        "common": "Åland Islands",
        "official": "Åland Islands",
        "native": {
          "swe": {
            "official": "Landskapet Åland",
            "common": "Åland"
          }
        }
      },
      "iso2": "AX",
      "ccn3": "248",
      "cca3": "ALA",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Mariehamn"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "swe": "Swedish"
      },
      "latlng": [
        60.116667,
        19.9
      ],
      "flag": "🇦🇽",
      "area": 1580
    },
    {
      "name": {
        "common": "Albania",
        "official": "Republic of Albania",
        "native": {
          "sqi": {
            "official": "Republika e Shqipërisë",
            "common": "Shqipëria"
          }
        }
      },
      "iso2": "AL",
      "ccn3": "008",
      "cca3": "ALB",
      "cioc": "ALB",
      "currencies": {
        "ALL": {
          "name": "Albanian lek",
          "symbol": "L"
        }
      },
      "capital": [
        "Tirana"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "sqi": "Albanian"
      },
      "latlng": [
        41,
        20
      ],
      "flag": "🇦🇱",
      "area": 28748
    },
    {
      "name": {
        "common": "Andorra",
        "official": "Principality of Andorra",
        "native": {
          "cat": {
            "official": "Principat d'Andorra",
            "common": "Andorra"
          }
        }
      },
      "iso2": "AD",
      "ccn3": "020",
      "cca3": "AND",
      "cioc": "AND",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Andorra la Vella"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "cat": "Catalan"
      },
      "latlng": [
        42.5,
        1.5
      ],
      "flag": "🇦🇩",
      "area": 468
    },
    {
      "name": {
        "common": "United Arab Emirates",
        "official": "United Arab Emirates",
        "native": {
          "ara": {
            "official": "الإمارات العربية المتحدة",
            "common": "دولة الإمارات العربية المتحدة"
          }
        }
      },
      "iso2": "AE",
      "ccn3": "784",
      "cca3": "ARE",
      "cioc": "UAE",
      "currencies": {
        "AED": {
          "name": "United Arab Emirates dirham",
          "symbol": "د.إ"
        }
      },
      "capital": [
        "Abu Dhabi"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        24,
        54
      ],
      "flag": "🇦🇪",
      "area": 83600
    },
    {
      "name": {
        "common": "Argentina",
        "official": "Argentine Republic",
        "native": {
          "grn": {
            "official": "Argentine Republic",
            "common": "Argentina"
          },
          "spa": {
            "official": "República Argentina",
            "common": "Argentina"
          }
        }
      },
      "iso2": "AR",
      "ccn3": "032",
      "cca3": "ARG",
      "cioc": "ARG",
      "currencies": {
        "ARS": {
          "name": "Argentine peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Buenos Aires"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "grn": "Guaraní",
        "spa": "Spanish"
      },
      "latlng": [
        -34,
        -64
      ],
      "flag": "🇦🇷",
      "area": 2780400
    },
    {
      "name": {
        "common": "Armenia",
        "official": "Republic of Armenia",
        "native": {
          "hye": {
            "official": "Հայաստանի Հանրապետություն",
            "common": "Հայաստան"
          }
        }
      },
      "iso2": "AM",
      "ccn3": "051",
      "cca3": "ARM",
      "cioc": "ARM",
      "currencies": {
        "AMD": {
          "name": "Armenian dram",
          "symbol": "֏"
        }
      },
      "capital": [
        "Yerevan"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "hye": "Armenian"
      },
      "latlng": [
        40,
        45
      ],
      "flag": "🇦🇲",
      "area": 29743
    },
    {
      "name": {
        "common": "American Samoa",
        "official": "American Samoa",
        "native": {
          "eng": {
            "official": "American Samoa",
            "common": "American Samoa"
          },
          "smo": {
            "official": "Sāmoa Amelika",
            "common": "Sāmoa Amelika"
          }
        }
      },
      "iso2": "AS",
      "ccn3": "016",
      "cca3": "ASM",
      "cioc": "ASA",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Pago Pago"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "smo": "Samoan"
      },
      "latlng": [
        -14.33333333,
        -170
      ],
      "flag": "🇦🇸",
      "area": 199
    },
    {
      "name": {
        "common": "Antarctica",
        "official": "Antarctica",
        "native": {}
      },
      "iso2": "AQ",
      "ccn3": "010",
      "cca3": "ATA",
      "cioc": "",
      "currencies": [],
      "capital": [
        ""
      ],
      "region": "Antarctic",
      "subregion": "",
      "languages": {},
      "latlng": [
        -90,
        0
      ],
      "flag": "🇦🇶",
      "area": 14000000
    },
    {
      "name": {
        "common": "French Southern and Antarctic Lands",
        "official": "Territory of the French Southern and Antarctic Lands",
        "native": {
          "fra": {
            "official": "Territoire des Terres australes et antarctiques françaises",
            "common": "Terres australes et antarctiques françaises"
          }
        }
      },
      "iso2": "TF",
      "ccn3": "260",
      "cca3": "ATF",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Port-aux-Français"
      ],
      "region": "Antarctic",
      "subregion": "",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -49.25,
        69.167
      ],
      "flag": "🇹🇫",
      "area": 7747
    },
    {
      "name": {
        "common": "Antigua and Barbuda",
        "official": "Antigua and Barbuda",
        "native": {
          "eng": {
            "official": "Antigua and Barbuda",
            "common": "Antigua and Barbuda"
          }
        }
      },
      "iso2": "AG",
      "ccn3": "028",
      "cca3": "ATG",
      "cioc": "ANT",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Saint John's"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        17.05,
        -61.8
      ],
      "flag": "🇦🇬",
      "area": 442
    },
    {
      "name": {
        "common": "Australia",
        "official": "Commonwealth of Australia",
        "native": {
          "eng": {
            "official": "Commonwealth of Australia",
            "common": "Australia"
          }
        }
      },
      "iso2": "AU",
      "ccn3": "036",
      "cca3": "AUS",
      "cioc": "AUS",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Canberra"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -27,
        133
      ],
      "flag": "🇦🇺",
      "area": 7692024
    },
    {
      "name": {
        "common": "Austria",
        "official": "Republic of Austria",
        "native": {
          "bar": {
            "official": "Republik Österreich",
            "common": "Österreich"
          }
        }
      },
      "iso2": "AT",
      "ccn3": "040",
      "cca3": "AUT",
      "cioc": "AUT",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Vienna"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "languages": {
        "bar": "Austro-Bavarian German"
      },
      "latlng": [
        47.33333333,
        13.33333333
      ],
      "flag": "🇦🇹",
      "area": 83871
    },
    {
      "name": {
        "common": "Azerbaijan",
        "official": "Republic of Azerbaijan",
        "native": {
          "aze": {
            "official": "Azərbaycan Respublikası",
            "common": "Azərbaycan"
          },
          "rus": {
            "official": "Азербайджанская Республика",
            "common": "Азербайджан"
          }
        }
      },
      "iso2": "AZ",
      "ccn3": "031",
      "cca3": "AZE",
      "cioc": "AZE",
      "currencies": {
        "AZN": {
          "name": "Azerbaijani manat",
          "symbol": "₼"
        }
      },
      "capital": [
        "Baku"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "aze": "Azerbaijani",
        "rus": "Russian"
      },
      "latlng": [
        40.5,
        47.5
      ],
      "flag": "🇦🇿",
      "area": 86600
    },
    {
      "name": {
        "common": "Burundi",
        "official": "Republic of Burundi",
        "native": {
          "fra": {
            "official": "République du Burundi",
            "common": "Burundi"
          },
          "run": {
            "official": "Republika y'Uburundi ",
            "common": "Uburundi"
          }
        }
      },
      "iso2": "BI",
      "ccn3": "108",
      "cca3": "BDI",
      "cioc": "BDI",
      "currencies": {
        "BIF": {
          "name": "Burundian franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Gitega"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "fra": "French",
        "run": "Kirundi"
      },
      "latlng": [
        -3.5,
        30
      ],
      "flag": "🇧🇮",
      "area": 27834
    },
    {
      "name": {
        "common": "Belgium",
        "official": "Kingdom of Belgium",
        "native": {
          "deu": {
            "official": "Königreich Belgien",
            "common": "Belgien"
          },
          "fra": {
            "official": "Royaume de Belgique",
            "common": "Belgique"
          },
          "nld": {
            "official": "Koninkrijk België",
            "common": "België"
          }
        }
      },
      "iso2": "BE",
      "ccn3": "056",
      "cca3": "BEL",
      "cioc": "BEL",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Brussels"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "deu": "German",
        "fra": "French",
        "nld": "Dutch"
      },
      "latlng": [
        50.83333333,
        4
      ],
      "flag": "🇧🇪",
      "area": 30528
    },
    {
      "name": {
        "common": "Benin",
        "official": "Republic of Benin",
        "native": {
          "fra": {
            "official": "République du Bénin",
            "common": "Bénin"
          }
        }
      },
      "iso2": "BJ",
      "ccn3": "204",
      "cca3": "BEN",
      "cioc": "BEN",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Porto-Novo"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        9.5,
        2.25
      ],
      "flag": "🇧🇯",
      "area": 112622
    },
    {
      "name": {
        "common": "Burkina Faso",
        "official": "Burkina Faso",
        "native": {
          "fra": {
            "official": "République du Burkina",
            "common": "Burkina Faso"
          }
        }
      },
      "iso2": "BF",
      "ccn3": "854",
      "cca3": "BFA",
      "cioc": "BUR",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Ouagadougou"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        13,
        -2
      ],
      "flag": "🇧🇫",
      "area": 272967
    },
    {
      "name": {
        "common": "Bangladesh",
        "official": "People's Republic of Bangladesh",
        "native": {
          "ben": {
            "official": "বাংলাদেশ গণপ্রজাতন্ত্রী",
            "common": "বাংলাদেশ"
          }
        }
      },
      "iso2": "BD",
      "ccn3": "050",
      "cca3": "BGD",
      "cioc": "BAN",
      "currencies": {
        "BDT": {
          "name": "Bangladeshi taka",
          "symbol": "৳"
        }
      },
      "capital": [
        "Dhaka"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "ben": "Bengali"
      },
      "latlng": [
        24,
        90
      ],
      "flag": "🇧🇩",
      "area": 147570
    },
    {
      "name": {
        "common": "Bulgaria",
        "official": "Republic of Bulgaria",
        "native": {
          "bul": {
            "official": "Република България",
            "common": "България"
          }
        }
      },
      "iso2": "BG",
      "ccn3": "100",
      "cca3": "BGR",
      "cioc": "BUL",
      "currencies": {
        "BGN": {
          "name": "Bulgarian lev",
          "symbol": "лв"
        }
      },
      "capital": [
        "Sofia"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "bul": "Bulgarian"
      },
      "latlng": [
        43,
        25
      ],
      "flag": "🇧🇬",
      "area": 110879
    },
    {
      "name": {
        "common": "Bahrain",
        "official": "Kingdom of Bahrain",
        "native": {
          "ara": {
            "official": "مملكة البحرين",
            "common": "‏البحرين"
          }
        }
      },
      "iso2": "BH",
      "ccn3": "048",
      "cca3": "BHR",
      "cioc": "BRN",
      "currencies": {
        "BHD": {
          "name": "Bahraini dinar",
          "symbol": ".د.ب"
        }
      },
      "capital": [
        "Manama"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        26,
        50.55
      ],
      "flag": "🇧🇭",
      "area": 765
    },
    {
      "name": {
        "common": "Bahamas",
        "official": "Commonwealth of the Bahamas",
        "native": {
          "eng": {
            "official": "Commonwealth of the Bahamas",
            "common": "Bahamas"
          }
        }
      },
      "iso2": "BS",
      "ccn3": "044",
      "cca3": "BHS",
      "cioc": "BAH",
      "currencies": {
        "BSD": {
          "name": "Bahamian dollar",
          "symbol": "$"
        },
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Nassau"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        24.25,
        -76
      ],
      "flag": "🇧🇸",
      "area": 13943
    },
    {
      "name": {
        "common": "Bosnia and Herzegovina",
        "official": "Bosnia and Herzegovina",
        "native": {
          "bos": {
            "official": "Bosna i Hercegovina",
            "common": "Bosna i Hercegovina"
          },
          "hrv": {
            "official": "Bosna i Hercegovina",
            "common": "Bosna i Hercegovina"
          },
          "srp": {
            "official": "Боснa и Херцеговина",
            "common": "Боснa и Херцеговина"
          }
        }
      },
      "iso2": "BA",
      "ccn3": "070",
      "cca3": "BIH",
      "cioc": "BIH",
      "currencies": {
        "BAM": {
          "name": "Bosnia and Herzegovina convertible mark",
          "symbol": ""
        }
      },
      "capital": [
        "Sarajevo"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "bos": "Bosnian",
        "hrv": "Croatian",
        "srp": "Serbian"
      },
      "latlng": [
        44,
        18
      ],
      "flag": "🇧🇦",
      "area": 51209
    },
    {
      "name": {
        "common": "Saint Barthélemy",
        "official": "Collectivity of Saint Barthélemy",
        "native": {
          "fra": {
            "official": "Collectivité de Saint-Barthélemy",
            "common": "Saint-Barthélemy"
          }
        }
      },
      "iso2": "BL",
      "ccn3": "652",
      "cca3": "BLM",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Gustavia"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        18.5,
        -63.41666666
      ],
      "flag": "🇧🇱",
      "area": 21
    },
    {
      "name": {
        "common": "Saint Helena, Ascension and Tristan da Cunha",
        "official": "Saint Helena, Ascension and Tristan da Cunha",
        "native": {
          "eng": {
            "official": "Saint Helena, Ascension and Tristan da Cunha",
            "common": "Saint Helena, Ascension and Tristan da Cunha"
          }
        }
      },
      "iso2": "SH",
      "ccn3": "654",
      "cca3": "SHN",
      "cioc": "",
      "currencies": {
        "GBP": {
          "name": "Pound sterling",
          "symbol": "£"
        },
        "SHP": {
          "name": "Saint Helena pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Jamestown"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -15.95,
        -5.72
      ],
      "flag": "🇸🇭",
      "area": 394
    },
    {
      "name": {
        "common": "Belarus",
        "official": "Republic of Belarus",
        "native": {
          "bel": {
            "official": "Рэспубліка Беларусь",
            "common": "Белару́сь"
          },
          "rus": {
            "official": "Республика Беларусь",
            "common": "Беларусь"
          }
        }
      },
      "iso2": "BY",
      "ccn3": "112",
      "cca3": "BLR",
      "cioc": "BLR",
      "currencies": {
        "BYN": {
          "name": "Belarusian ruble",
          "symbol": "Br"
        }
      },
      "capital": [
        "Minsk"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "languages": {
        "bel": "Belarusian",
        "rus": "Russian"
      },
      "latlng": [
        53,
        28
      ],
      "flag": "🇧🇾",
      "area": 207600
    },
    {
      "name": {
        "common": "Belize",
        "official": "Belize",
        "native": {
          "bjz": {
            "official": "Belize",
            "common": "Belize"
          },
          "eng": {
            "official": "Belize",
            "common": "Belize"
          },
          "spa": {
            "official": "Belice",
            "common": "Belice"
          }
        }
      },
      "iso2": "BZ",
      "ccn3": "084",
      "cca3": "BLZ",
      "cioc": "BIZ",
      "currencies": {
        "BZD": {
          "name": "Belize dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Belmopan"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "bjz": "Belizean Creole",
        "eng": "English",
        "spa": "Spanish"
      },
      "latlng": [
        17.25,
        -88.75
      ],
      "flag": "🇧🇿",
      "area": 22966
    },
    {
      "name": {
        "common": "Bermuda",
        "official": "Bermuda",
        "native": {
          "eng": {
            "official": "Bermuda",
            "common": "Bermuda"
          }
        }
      },
      "iso2": "BM",
      "ccn3": "060",
      "cca3": "BMU",
      "cioc": "BER",
      "currencies": {
        "BMD": {
          "name": "Bermudian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Hamilton"
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        32.33333333,
        -64.75
      ],
      "flag": "🇧🇲",
      "area": 54
    },
    {
      "name": {
        "common": "Bolivia",
        "official": "Plurinational State of Bolivia",
        "native": {
          "aym": {
            "official": "Wuliwya Suyu",
            "common": "Wuliwya"
          },
          "grn": {
            "official": "Tetã Volívia",
            "common": "Volívia"
          },
          "que": {
            "official": "Buliwya Mamallaqta",
            "common": "Buliwya"
          },
          "spa": {
            "official": "Estado Plurinacional de Bolivia",
            "common": "Bolivia"
          }
        }
      },
      "iso2": "BO",
      "ccn3": "068",
      "cca3": "BOL",
      "cioc": "BOL",
      "currencies": {
        "BOB": {
          "name": "Bolivian boliviano",
          "symbol": "Bs."
        }
      },
      "capital": [
        "Sucre"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "aym": "Aymara",
        "grn": "Guaraní",
        "que": "Quechua",
        "spa": "Spanish"
      },
      "latlng": [
        -17,
        -65
      ],
      "flag": "🇧🇴",
      "area": 1098581
    },
    {
      "name": {
        "common": "Caribbean Netherlands",
        "official": "Bonaire, Sint Eustatius and Saba",
        "native": {
          "nld": {
            "official": "Bonaire, Sint Eustatius en Saba",
            "common": "Caribisch Nederland"
          },
          "pap": {
            "official": "Boneiru, Sint Eustatius y Saba",
            "common": "Boneiru, Sint Eustatius y Saba"
          }
        }
      },
      "iso2": "BQ",
      "ccn3": "535",
      "cca3": "BES",
      "cioc": "",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English",
        "nld": "Dutch",
        "pap": "Papiamento"
      },
      "latlng": [
        12.18,
        -68.25
      ],
      "flag": "",
      "area": 328
    },
    {
      "name": {
        "common": "Brazil",
        "official": "Federative Republic of Brazil",
        "native": {
          "por": {
            "official": "República Federativa do Brasil",
            "common": "Brasil"
          }
        }
      },
      "iso2": "BR",
      "ccn3": "076",
      "cca3": "BRA",
      "cioc": "BRA",
      "currencies": {
        "BRL": {
          "name": "Brazilian real",
          "symbol": "R$"
        }
      },
      "capital": [
        "Brasília"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "por": "Portuguese"
      },
      "latlng": [
        -10,
        -55
      ],
      "flag": "🇧🇷",
      "area": 8515767
    },
    {
      "name": {
        "common": "Barbados",
        "official": "Barbados",
        "native": {
          "eng": {
            "official": "Barbados",
            "common": "Barbados"
          }
        }
      },
      "iso2": "BB",
      "ccn3": "052",
      "cca3": "BRB",
      "cioc": "BAR",
      "currencies": {
        "BBD": {
          "name": "Barbadian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Bridgetown"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        13.16666666,
        -59.53333333
      ],
      "flag": "🇧🇧",
      "area": 430
    },
    {
      "name": {
        "common": "Brunei",
        "official": "Nation of Brunei, Abode of Peace",
        "native": {
          "msa": {
            "official": "Nation of Brunei, Abode Damai",
            "common": "Negara Brunei Darussalam"
          }
        }
      },
      "iso2": "BN",
      "ccn3": "096",
      "cca3": "BRN",
      "cioc": "BRU",
      "currencies": {
        "BND": {
          "name": "Brunei dollar",
          "symbol": "$"
        },
        "SGD": {
          "name": "Singapore dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Bandar Seri Begawan"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "msa": "Malay"
      },
      "latlng": [
        4.5,
        114.66666666
      ],
      "flag": "🇧🇳",
      "area": 5765
    },
    {
      "name": {
        "common": "Bhutan",
        "official": "Kingdom of Bhutan",
        "native": {
          "dzo": {
            "official": "འབྲུག་རྒྱལ་ཁབ་",
            "common": "འབྲུག་ཡུལ་"
          }
        }
      },
      "iso2": "BT",
      "ccn3": "064",
      "cca3": "BTN",
      "cioc": "BHU",
      "currencies": {
        "BTN": {
          "name": "Bhutanese ngultrum",
          "symbol": "Nu."
        },
        "INR": {
          "name": "Indian rupee",
          "symbol": "₹"
        }
      },
      "capital": [
        "Thimphu"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "dzo": "Dzongkha"
      },
      "latlng": [
        27.5,
        90.5
      ],
      "flag": "🇧🇹",
      "area": 38394
    },
    {
      "name": {
        "common": "Bouvet Island",
        "official": "Bouvet Island",
        "native": {
          "nor": {
            "official": "Bouvetøya",
            "common": "Bouvetøya"
          }
        }
      },
      "iso2": "BV",
      "ccn3": "074",
      "cca3": "BVT",
      "cioc": "",
      "currencies": [],
      "capital": [
        ""
      ],
      "region": "Antarctic",
      "subregion": "",
      "languages": {
        "nor": "Norwegian"
      },
      "latlng": [
        -54.43333333,
        3.4
      ],
      "flag": "🇧🇻",
      "area": 49
    },
    {
      "name": {
        "common": "Botswana",
        "official": "Republic of Botswana",
        "native": {
          "eng": {
            "official": "Republic of Botswana",
            "common": "Botswana"
          },
          "tsn": {
            "official": "Lefatshe la Botswana",
            "common": "Botswana"
          }
        }
      },
      "iso2": "BW",
      "ccn3": "072",
      "cca3": "BWA",
      "cioc": "BOT",
      "currencies": {
        "BWP": {
          "name": "Botswana pula",
          "symbol": "P"
        }
      },
      "capital": [
        "Gaborone"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "languages": {
        "eng": "English",
        "tsn": "Tswana"
      },
      "latlng": [
        -22,
        24
      ],
      "flag": "🇧🇼",
      "area": 582000
    },
    {
      "name": {
        "common": "Central African Republic",
        "official": "Central African Republic",
        "native": {
          "fra": {
            "official": "République centrafricaine",
            "common": "République centrafricaine"
          },
          "sag": {
            "official": "Ködörösêse tî Bêafrîka",
            "common": "Bêafrîka"
          }
        }
      },
      "iso2": "CF",
      "ccn3": "140",
      "cca3": "CAF",
      "cioc": "CAF",
      "currencies": {
        "XAF": {
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Bangui"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "fra": "French",
        "sag": "Sango"
      },
      "latlng": [
        7,
        21
      ],
      "flag": "🇨🇫",
      "area": 622984
    },
    {
      "name": {
        "common": "Canada",
        "official": "Canada",
        "native": {
          "eng": {
            "official": "Canada",
            "common": "Canada"
          },
          "fra": {
            "official": "Canada",
            "common": "Canada"
          }
        }
      },
      "iso2": "CA",
      "ccn3": "124",
      "cca3": "CAN",
      "cioc": "CAN",
      "currencies": {
        "CAD": {
          "name": "Canadian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Ottawa"
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "eng": "English",
        "fra": "French"
      },
      "latlng": [
        60,
        -95
      ],
      "flag": "🇨🇦",
      "area": 9984670
    },
    {
      "name": {
        "common": "Cocos (Keeling) Islands",
        "official": "Territory of the Cocos (Keeling) Islands",
        "native": {
          "eng": {
            "official": "Territory of the Cocos (Keeling) Islands",
            "common": "Cocos (Keeling) Islands"
          }
        }
      },
      "iso2": "CC",
      "ccn3": "166",
      "cca3": "CCK",
      "cioc": "",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "West Island"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -12.5,
        96.83333333
      ],
      "flag": "🇨🇨",
      "area": 14
    },
    {
      "name": {
        "common": "Switzerland",
        "official": "Swiss Confederation",
        "native": {
          "fra": {
            "official": "Confédération suisse",
            "common": "Suisse"
          },
          "gsw": {
            "official": "Schweizerische Eidgenossenschaft",
            "common": "Schweiz"
          },
          "ita": {
            "official": "Confederazione Svizzera",
            "common": "Svizzera"
          },
          "roh": {
            "official": "Confederaziun svizra",
            "common": "Svizra"
          }
        }
      },
      "iso2": "CH",
      "ccn3": "756",
      "cca3": "CHE",
      "cioc": "SUI",
      "currencies": {
        "CHF": {
          "name": "Swiss franc",
          "symbol": "Fr."
        }
      },
      "capital": [
        "Bern"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "fra": "French",
        "gsw": "Swiss German",
        "ita": "Italian",
        "roh": "Romansh"
      },
      "latlng": [
        47,
        8
      ],
      "flag": "🇨🇭",
      "area": 41284
    },
    {
      "name": {
        "common": "Chile",
        "official": "Republic of Chile",
        "native": {
          "spa": {
            "official": "República de Chile",
            "common": "Chile"
          }
        }
      },
      "iso2": "CL",
      "ccn3": "152",
      "cca3": "CHL",
      "cioc": "CHI",
      "currencies": {
        "CLP": {
          "name": "Chilean peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Santiago"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        -30,
        -71
      ],
      "flag": "🇨🇱",
      "area": 756102
    },
    {
      "name": {
        "common": "China",
        "official": "People's Republic of China",
        "native": {
          "zho": {
            "official": "中华人民共和国",
            "common": "中国"
          }
        }
      },
      "iso2": "CN",
      "ccn3": "156",
      "cca3": "CHN",
      "cioc": "CHN",
      "currencies": {
        "CNY": {
          "name": "Chinese yuan",
          "symbol": "¥"
        }
      },
      "capital": [
        "Beijing"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "zho": "Chinese"
      },
      "latlng": [
        35,
        105
      ],
      "flag": "🇨🇳",
      "area": 9706961
    },
    {
      "name": {
        "common": "Ivory Coast",
        "official": "Republic of Côte d'Ivoire",
        "native": {
          "fra": {
            "official": "République de Côte d'Ivoire",
            "common": "Côte d'Ivoire"
          }
        }
      },
      "iso2": "CI",
      "ccn3": "384",
      "cca3": "CIV",
      "cioc": "CIV",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Yamoussoukro"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        8,
        -5
      ],
      "flag": "🇨🇮",
      "area": 322463
    },
    {
      "name": {
        "common": "Cameroon",
        "official": "Republic of Cameroon",
        "native": {
          "eng": {
            "official": "Republic of Cameroon",
            "common": "Cameroon"
          },
          "fra": {
            "official": "République du Cameroun",
            "common": "Cameroun"
          }
        }
      },
      "iso2": "CM",
      "ccn3": "120",
      "cca3": "CMR",
      "cioc": "CMR",
      "currencies": {
        "XAF": {
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Yaoundé"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "eng": "English",
        "fra": "French"
      },
      "latlng": [
        6,
        12
      ],
      "flag": "🇨🇲",
      "area": 475442
    },
    {
      "name": {
        "common": "DR Congo",
        "official": "Democratic Republic of the Congo",
        "native": {
          "fra": {
            "official": "République démocratique du Congo",
            "common": "RD Congo"
          },
          "kon": {
            "official": "Repubilika ya Kongo Demokratiki",
            "common": "Repubilika ya Kongo Demokratiki"
          },
          "lin": {
            "official": "Republiki ya Kongó Demokratiki",
            "common": "Republiki ya Kongó Demokratiki"
          },
          "lua": {
            "official": "Ditunga dia Kongu wa Mungalaata",
            "common": "Ditunga dia Kongu wa Mungalaata"
          },
          "swa": {
            "official": "Jamhuri ya Kidemokrasia ya Kongo",
            "common": "Jamhuri ya Kidemokrasia ya Kongo"
          }
        }
      },
      "iso2": "CD",
      "ccn3": "180",
      "cca3": "COD",
      "cioc": "COD",
      "currencies": {
        "CDF": {
          "name": "Congolese franc",
          "symbol": "FC"
        }
      },
      "capital": [
        "Kinshasa"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "fra": "French",
        "kon": "Kikongo",
        "lin": "Lingala",
        "lua": "Tshiluba",
        "swa": "Swahili"
      },
      "latlng": [
        0,
        25
      ],
      "flag": "🇨🇩",
      "area": 2344858
    },
    {
      "name": {
        "common": "Republic of the Congo",
        "official": "Republic of the Congo",
        "native": {
          "fra": {
            "official": "République du Congo",
            "common": "République du Congo"
          },
          "kon": {
            "official": "Repubilika ya Kongo",
            "common": "Repubilika ya Kongo"
          },
          "lin": {
            "official": "Republíki ya Kongó",
            "common": "Republíki ya Kongó"
          }
        }
      },
      "iso2": "CG",
      "ccn3": "178",
      "cca3": "COG",
      "cioc": "CGO",
      "currencies": {
        "XAF": {
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Brazzaville"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "fra": "French",
        "kon": "Kikongo",
        "lin": "Lingala"
      },
      "latlng": [
        -1,
        15
      ],
      "flag": "🇨🇬",
      "area": 342000
    },
    {
      "name": {
        "common": "Cook Islands",
        "official": "Cook Islands",
        "native": {
          "eng": {
            "official": "Cook Islands",
            "common": "Cook Islands"
          },
          "rar": {
            "official": "Kūki 'Āirani",
            "common": "Kūki 'Āirani"
          }
        }
      },
      "iso2": "CK",
      "ccn3": "184",
      "cca3": "COK",
      "cioc": "COK",
      "currencies": {
        "CKD": {
          "name": "Cook Islands dollar",
          "symbol": "$"
        },
        "NZD": {
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Avarua"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "rar": "Cook Islands Māori"
      },
      "latlng": [
        -21.23333333,
        -159.76666666
      ],
      "flag": "🇨🇰",
      "area": 236
    },
    {
      "name": {
        "common": "Colombia",
        "official": "Republic of Colombia",
        "native": {
          "spa": {
            "official": "República de Colombia",
            "common": "Colombia"
          }
        }
      },
      "iso2": "CO",
      "ccn3": "170",
      "cca3": "COL",
      "cioc": "COL",
      "currencies": {
        "COP": {
          "name": "Colombian peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Bogotá"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        4,
        -72
      ],
      "flag": "🇨🇴",
      "area": 1141748
    },
    {
      "name": {
        "common": "Comoros",
        "official": "Union of the Comoros",
        "native": {
          "ara": {
            "official": "الاتحاد القمري",
            "common": "القمر‎"
          },
          "fra": {
            "official": "Union des Comores",
            "common": "Comores"
          },
          "zdj": {
            "official": "Udzima wa Komori",
            "common": "Komori"
          }
        }
      },
      "iso2": "KM",
      "ccn3": "174",
      "cca3": "COM",
      "cioc": "COM",
      "currencies": {
        "KMF": {
          "name": "Comorian franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Moroni"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "ara": "Arabic",
        "fra": "French",
        "zdj": "Comorian"
      },
      "latlng": [
        -12.16666666,
        44.25
      ],
      "flag": "🇰🇲",
      "area": 1862
    },
    {
      "name": {
        "common": "Cape Verde",
        "official": "Republic of Cabo Verde",
        "native": {
          "por": {
            "official": "República de Cabo Verde",
            "common": "Cabo Verde"
          }
        }
      },
      "iso2": "CV",
      "ccn3": "132",
      "cca3": "CPV",
      "cioc": "CPV",
      "currencies": {
        "CVE": {
          "name": "Cape Verdean escudo",
          "symbol": "Esc"
        }
      },
      "capital": [
        "Praia"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "por": "Portuguese"
      },
      "latlng": [
        16,
        -24
      ],
      "flag": "🇨🇻",
      "area": 4033
    },
    {
      "name": {
        "common": "Costa Rica",
        "official": "Republic of Costa Rica",
        "native": {
          "spa": {
            "official": "República de Costa Rica",
            "common": "Costa Rica"
          }
        }
      },
      "iso2": "CR",
      "ccn3": "188",
      "cca3": "CRI",
      "cioc": "CRC",
      "currencies": {
        "CRC": {
          "name": "Costa Rican colón",
          "symbol": "₡"
        }
      },
      "capital": [
        "San José"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        10,
        -84
      ],
      "flag": "🇨🇷",
      "area": 51100
    },
    {
      "name": {
        "common": "Cuba",
        "official": "Republic of Cuba",
        "native": {
          "spa": {
            "official": "República de Cuba",
            "common": "Cuba"
          }
        }
      },
      "iso2": "CU",
      "ccn3": "192",
      "cca3": "CUB",
      "cioc": "CUB",
      "currencies": {
        "CUC": {
          "name": "Cuban convertible peso",
          "symbol": "$"
        },
        "CUP": {
          "name": "Cuban peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Havana"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        21.5,
        -80
      ],
      "flag": "🇨🇺",
      "area": 109884
    },
    {
      "name": {
        "common": "Curaçao",
        "official": "Country of Curaçao",
        "native": {
          "eng": {
            "official": "Country of Curaçao",
            "common": "Curaçao"
          },
          "nld": {
            "official": "Land Curaçao",
            "common": "Curaçao"
          },
          "pap": {
            "official": "Pais Kòrsou",
            "common": "Pais Kòrsou"
          }
        }
      },
      "iso2": "CW",
      "ccn3": "531",
      "cca3": "CUW",
      "cioc": "",
      "currencies": {
        "ANG": {
          "name": "Netherlands Antillean guilder",
          "symbol": "ƒ"
        }
      },
      "capital": [
        "Willemstad"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English",
        "nld": "Dutch",
        "pap": "Papiamento"
      },
      "latlng": [
        12.116667,
        -68.933333
      ],
      "flag": "🇨🇼",
      "area": 444
    },
    {
      "name": {
        "common": "Christmas Island",
        "official": "Territory of Christmas Island",
        "native": {
          "eng": {
            "official": "Territory of Christmas Island",
            "common": "Christmas Island"
          }
        }
      },
      "iso2": "CX",
      "ccn3": "162",
      "cca3": "CXR",
      "cioc": "",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Flying Fish Cove"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -10.5,
        105.66666666
      ],
      "flag": "🇨🇽",
      "area": 135
    },
    {
      "name": {
        "common": "Cayman Islands",
        "official": "Cayman Islands",
        "native": {
          "eng": {
            "official": "Cayman Islands",
            "common": "Cayman Islands"
          }
        }
      },
      "iso2": "KY",
      "ccn3": "136",
      "cca3": "CYM",
      "cioc": "CAY",
      "currencies": {
        "KYD": {
          "name": "Cayman Islands dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "George Town"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        19.5,
        -80.5
      ],
      "flag": "🇰🇾",
      "area": 264
    },
    {
      "name": {
        "common": "Cyprus",
        "official": "Republic of Cyprus",
        "native": {
          "ell": {
            "official": "Δημοκρατία της Κύπρος",
            "common": "Κύπρος"
          },
          "tur": {
            "official": "Kıbrıs Cumhuriyeti",
            "common": "Kıbrıs"
          }
        }
      },
      "iso2": "CY",
      "ccn3": "196",
      "cca3": "CYP",
      "cioc": "CYP",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Nicosia"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "ell": "Greek",
        "tur": "Turkish"
      },
      "latlng": [
        35,
        33
      ],
      "flag": "🇨🇾",
      "area": 9251
    },
    {
      "name": {
        "common": "Czechia",
        "official": "Czech Republic",
        "native": {
          "ces": {
            "official": "Česká republika",
            "common": "Česko"
          },
          "slk": {
            "official": "Česká republika",
            "common": "Česko"
          }
        }
      },
      "iso2": "CZ",
      "ccn3": "203",
      "cca3": "CZE",
      "cioc": "CZE",
      "currencies": {
        "CZK": {
          "name": "Czech koruna",
          "symbol": "Kč"
        }
      },
      "capital": [
        "Prague"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "languages": {
        "ces": "Czech",
        "slk": "Slovak"
      },
      "latlng": [
        49.75,
        15.5
      ],
      "flag": "🇨🇿",
      "area": 78865
    },
    {
      "name": {
        "common": "Germany",
        "official": "Federal Republic of Germany",
        "native": {
          "deu": {
            "official": "Bundesrepublik Deutschland",
            "common": "Deutschland"
          }
        }
      },
      "iso2": "DE",
      "ccn3": "276",
      "cca3": "DEU",
      "cioc": "GER",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Berlin"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "deu": "German"
      },
      "latlng": [
        51,
        9
      ],
      "flag": "🇩🇪",
      "area": 357114
    },
    {
      "name": {
        "common": "Djibouti",
        "official": "Republic of Djibouti",
        "native": {
          "ara": {
            "official": "جمهورية جيبوتي",
            "common": "جيبوتي‎"
          },
          "fra": {
            "official": "République de Djibouti",
            "common": "Djibouti"
          }
        }
      },
      "iso2": "DJ",
      "ccn3": "262",
      "cca3": "DJI",
      "cioc": "DJI",
      "currencies": {
        "DJF": {
          "name": "Djiboutian franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Djibouti"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "ara": "Arabic",
        "fra": "French"
      },
      "latlng": [
        11.5,
        43
      ],
      "flag": "🇩🇯",
      "area": 23200
    },
    {
      "name": {
        "common": "Dominica",
        "official": "Commonwealth of Dominica",
        "native": {
          "eng": {
            "official": "Commonwealth of Dominica",
            "common": "Dominica"
          }
        }
      },
      "iso2": "DM",
      "ccn3": "212",
      "cca3": "DMA",
      "cioc": "DMA",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Roseau"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        15.41666666,
        -61.33333333
      ],
      "flag": "🇩🇲",
      "area": 751
    },
    {
      "name": {
        "common": "Denmark",
        "official": "Kingdom of Denmark",
        "native": {
          "dan": {
            "official": "Kongeriget Danmark",
            "common": "Danmark"
          }
        }
      },
      "iso2": "DK",
      "ccn3": "208",
      "cca3": "DNK",
      "cioc": "DEN",
      "currencies": {
        "DKK": {
          "name": "Danish krone",
          "symbol": "kr"
        }
      },
      "capital": [
        "Copenhagen"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "dan": "Danish"
      },
      "latlng": [
        56,
        10
      ],
      "flag": "🇩🇰",
      "area": 43094
    },
    {
      "name": {
        "common": "Dominican Republic",
        "official": "Dominican Republic",
        "native": {
          "spa": {
            "official": "República Dominicana",
            "common": "República Dominicana"
          }
        }
      },
      "iso2": "DO",
      "ccn3": "214",
      "cca3": "DOM",
      "cioc": "DOM",
      "currencies": {
        "DOP": {
          "name": "Dominican peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Santo Domingo"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        19,
        -70.66666666
      ],
      "flag": "🇩🇴",
      "area": 48671
    },
    {
      "name": {
        "common": "Algeria",
        "official": "People's Democratic Republic of Algeria",
        "native": {
          "ara": {
            "official": "الجمهورية الديمقراطية الشعبية الجزائرية",
            "common": "الجزائر"
          }
        }
      },
      "iso2": "DZ",
      "ccn3": "012",
      "cca3": "DZA",
      "cioc": "ALG",
      "currencies": {
        "DZD": {
          "name": "Algerian dinar",
          "symbol": "د.ج"
        }
      },
      "capital": [
        "Algiers"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        28,
        3
      ],
      "flag": "🇩🇿",
      "area": 2381741
    },
    {
      "name": {
        "common": "Ecuador",
        "official": "Republic of Ecuador",
        "native": {
          "spa": {
            "official": "República del Ecuador",
            "common": "Ecuador"
          }
        }
      },
      "iso2": "EC",
      "ccn3": "218",
      "cca3": "ECU",
      "cioc": "ECU",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Quito"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        -2,
        -77.5
      ],
      "flag": "🇪🇨",
      "area": 276841
    },
    {
      "name": {
        "common": "Egypt",
        "official": "Arab Republic of Egypt",
        "native": {
          "ara": {
            "official": "جمهورية مصر العربية",
            "common": "مصر"
          }
        }
      },
      "iso2": "EG",
      "ccn3": "818",
      "cca3": "EGY",
      "cioc": "EGY",
      "currencies": {
        "EGP": {
          "name": "Egyptian pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Cairo"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        27,
        30
      ],
      "flag": "🇪🇬",
      "area": 1002450
    },
    {
      "name": {
        "common": "Eritrea",
        "official": "State of Eritrea",
        "native": {
          "ara": {
            "official": "دولة إرتريا",
            "common": "إرتريا‎"
          },
          "eng": {
            "official": "State of Eritrea",
            "common": "Eritrea"
          },
          "tir": {
            "official": "ሃገረ ኤርትራ",
            "common": "ኤርትራ"
          }
        }
      },
      "iso2": "ER",
      "ccn3": "232",
      "cca3": "ERI",
      "cioc": "ERI",
      "currencies": {
        "ERN": {
          "name": "Eritrean nakfa",
          "symbol": "Nfk"
        }
      },
      "capital": [
        "Asmara"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "ara": "Arabic",
        "eng": "English",
        "tir": "Tigrinya"
      },
      "latlng": [
        15,
        39
      ],
      "flag": "🇪🇷",
      "area": 117600
    },
    {
      "name": {
        "common": "Western Sahara",
        "official": "Sahrawi Arab Democratic Republic",
        "native": {
          "ber": {
            "official": "Sahrawi Arab Democratic Republic",
            "common": "Western Sahara"
          },
          "mey": {
            "official": "الجمهورية العربية الصحراوية الديمقراطية",
            "common": "الصحراء الغربية"
          },
          "spa": {
            "official": "República Árabe Saharaui Democrática",
            "common": "Sahara Occidental"
          }
        }
      },
      "iso2": "EH",
      "ccn3": "732",
      "cca3": "ESH",
      "cioc": "",
      "currencies": {
        "DZD": {
          "name": "Algerian dinar",
          "symbol": "دج"
        },
        "MAD": {
          "name": "Moroccan dirham",
          "symbol": "DH"
        },
        "MRU": {
          "name": "Mauritanian ouguiya",
          "symbol": "UM"
        }
      },
      "capital": [
        "El Aaiún"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ber": "Berber",
        "mey": "Hassaniya",
        "spa": "Spanish"
      },
      "latlng": [
        24.5,
        -13
      ],
      "flag": "🇪🇭",
      "area": 266000
    },
    {
      "name": {
        "common": "Spain",
        "official": "Kingdom of Spain",
        "native": {
          "spa": {
            "official": "Reino de España",
            "common": "España"
          }
        }
      },
      "iso2": "ES",
      "ccn3": "724",
      "cca3": "ESP",
      "cioc": "ESP",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Madrid"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        40,
        -4
      ],
      "flag": "🇪🇸",
      "area": 505992
    },
    {
      "name": {
        "common": "Estonia",
        "official": "Republic of Estonia",
        "native": {
          "est": {
            "official": "Eesti Vabariik",
            "common": "Eesti"
          }
        }
      },
      "iso2": "EE",
      "ccn3": "233",
      "cca3": "EST",
      "cioc": "EST",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Tallinn"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "est": "Estonian"
      },
      "latlng": [
        59,
        26
      ],
      "flag": "🇪🇪",
      "area": 45227
    },
    {
      "name": {
        "common": "Ethiopia",
        "official": "Federal Democratic Republic of Ethiopia",
        "native": {
          "amh": {
            "official": "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
            "common": "ኢትዮጵያ"
          }
        }
      },
      "iso2": "ET",
      "ccn3": "231",
      "cca3": "ETH",
      "cioc": "ETH",
      "currencies": {
        "ETB": {
          "name": "Ethiopian birr",
          "symbol": "Br"
        }
      },
      "capital": [
        "Addis Ababa"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "amh": "Amharic"
      },
      "latlng": [
        8,
        38
      ],
      "flag": "🇪🇹",
      "area": 1104300
    },
    {
      "name": {
        "common": "Finland",
        "official": "Republic of Finland",
        "native": {
          "fin": {
            "official": "Suomen tasavalta",
            "common": "Suomi"
          },
          "swe": {
            "official": "Republiken Finland",
            "common": "Finland"
          }
        }
      },
      "iso2": "FI",
      "ccn3": "246",
      "cca3": "FIN",
      "cioc": "FIN",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Helsinki"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "fin": "Finnish",
        "swe": "Swedish"
      },
      "latlng": [
        64,
        26
      ],
      "flag": "🇫🇮",
      "area": 338424
    },
    {
      "name": {
        "common": "Fiji",
        "official": "Republic of Fiji",
        "native": {
          "eng": {
            "official": "Republic of Fiji",
            "common": "Fiji"
          },
          "fij": {
            "official": "Matanitu Tugalala o Viti",
            "common": "Viti"
          },
          "hif": {
            "official": "रिपब्लिक ऑफ फीजी",
            "common": "फिजी"
          }
        }
      },
      "iso2": "FJ",
      "ccn3": "242",
      "cca3": "FJI",
      "cioc": "FIJ",
      "currencies": {
        "FJD": {
          "name": "Fijian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Suva"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "languages": {
        "eng": "English",
        "fij": "Fijian",
        "hif": "Fiji Hindi"
      },
      "latlng": [
        -18,
        175
      ],
      "flag": "🇫🇯",
      "area": 18272
    },
    {
      "name": {
        "common": "Falkland Islands",
        "official": "Falkland Islands",
        "native": {
          "eng": {
            "official": "Falkland Islands",
            "common": "Falkland Islands"
          }
        }
      },
      "iso2": "FK",
      "ccn3": "238",
      "cca3": "FLK",
      "cioc": "",
      "currencies": {
        "FKP": {
          "name": "Falkland Islands pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Stanley"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -51.75,
        -59
      ],
      "flag": "🇫🇰",
      "area": 12173
    },
    {
      "name": {
        "common": "France",
        "official": "French Republic",
        "native": {
          "fra": {
            "official": "République française",
            "common": "France"
          }
        }
      },
      "iso2": "FR",
      "ccn3": "250",
      "cca3": "FRA",
      "cioc": "FRA",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Paris"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        46,
        2
      ],
      "flag": "🇫🇷",
      "area": 551695
    },
    {
      "name": {
        "common": "Faroe Islands",
        "official": "Faroe Islands",
        "native": {
          "dan": {
            "official": "Færøerne",
            "common": "Færøerne"
          },
          "fao": {
            "official": "Føroyar",
            "common": "Føroyar"
          }
        }
      },
      "iso2": "FO",
      "ccn3": "234",
      "cca3": "FRO",
      "cioc": "",
      "currencies": {
        "DKK": {
          "name": "Danish krone",
          "symbol": "kr"
        },
        "FOK": {
          "name": "Faroese króna",
          "symbol": "kr"
        }
      },
      "capital": [
        "Tórshavn"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "dan": "Danish",
        "fao": "Faroese"
      },
      "latlng": [
        62,
        -7
      ],
      "flag": "🇫🇴",
      "area": 1393
    },
    {
      "name": {
        "common": "Micronesia",
        "official": "Federated States of Micronesia",
        "native": {
          "eng": {
            "official": "Federated States of Micronesia",
            "common": "Micronesia"
          }
        }
      },
      "iso2": "FM",
      "ccn3": "583",
      "cca3": "FSM",
      "cioc": "FSM",
      "currencies": [],
      "capital": [
        "Palikir"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        6.91666666,
        158.25
      ],
      "flag": "🇫🇲",
      "area": 702
    },
    {
      "name": {
        "common": "Gabon",
        "official": "Gabonese Republic",
        "native": {
          "fra": {
            "official": "République gabonaise",
            "common": "Gabon"
          }
        }
      },
      "iso2": "GA",
      "ccn3": "266",
      "cca3": "GAB",
      "cioc": "GAB",
      "currencies": {
        "XAF": {
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Libreville"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -1,
        11.75
      ],
      "flag": "🇬🇦",
      "area": 267668
    },
    {
      "name": {
        "common": "United Kingdom",
        "official": "United Kingdom of Great Britain and Northern Ireland",
        "native": {
          "eng": {
            "official": "United Kingdom of Great Britain and Northern Ireland",
            "common": "United Kingdom"
          }
        }
      },
      "iso2": "GB",
      "ccn3": "826",
      "cca3": "GBR",
      "cioc": "GBR",
      "currencies": {
        "GBP": {
          "name": "British pound",
          "symbol": "£"
        }
      },
      "capital": [
        "London"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        54,
        -2
      ],
      "flag": "🇬🇧",
      "area": 242900
    },
    {
      "name": {
        "common": "Georgia",
        "official": "Georgia",
        "native": {
          "kat": {
            "official": "საქართველო",
            "common": "საქართველო"
          }
        }
      },
      "iso2": "GE",
      "ccn3": "268",
      "cca3": "GEO",
      "cioc": "GEO",
      "currencies": {
        "GEL": {
          "name": "lari",
          "symbol": "₾"
        }
      },
      "capital": [
        "Tbilisi"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "kat": "Georgian"
      },
      "latlng": [
        42,
        43.5
      ],
      "flag": "🇬🇪",
      "area": 69700
    },
    {
      "name": {
        "common": "Guernsey",
        "official": "Bailiwick of Guernsey",
        "native": {
          "eng": {
            "official": "Bailiwick of Guernsey",
            "common": "Guernsey"
          },
          "fra": {
            "official": "Bailliage de Guernesey",
            "common": "Guernesey"
          },
          "nfr": {
            "official": "Dgèrnésiais",
            "common": "Dgèrnésiais"
          }
        }
      },
      "iso2": "GG",
      "ccn3": "831",
      "cca3": "GGY",
      "cioc": "",
      "currencies": {
        "GBP": {
          "name": "British pound",
          "symbol": "£"
        },
        "GGP": {
          "name": "Guernsey pound",
          "symbol": "£"
        }
      },
      "capital": [
        "St. Peter Port"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "eng": "English",
        "fra": "French",
        "nfr": "Guernésiais"
      },
      "latlng": [
        49.46666666,
        -2.58333333
      ],
      "flag": "🇬🇬",
      "area": 78
    },
    {
      "name": {
        "common": "Ghana",
        "official": "Republic of Ghana",
        "native": {
          "eng": {
            "official": "Republic of Ghana",
            "common": "Ghana"
          }
        }
      },
      "iso2": "GH",
      "ccn3": "288",
      "cca3": "GHA",
      "cioc": "GHA",
      "currencies": {
        "GHS": {
          "name": "Ghanaian cedi",
          "symbol": "₵"
        }
      },
      "capital": [
        "Accra"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        8,
        -2
      ],
      "flag": "🇬🇭",
      "area": 238533
    },
    {
      "name": {
        "common": "Gibraltar",
        "official": "Gibraltar",
        "native": {
          "eng": {
            "official": "Gibraltar",
            "common": "Gibraltar"
          }
        }
      },
      "iso2": "GI",
      "ccn3": "292",
      "cca3": "GIB",
      "cioc": "",
      "currencies": {
        "GIP": {
          "name": "Gibraltar pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Gibraltar"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        36.13333333,
        -5.35
      ],
      "flag": "🇬🇮",
      "area": 6
    },
    {
      "name": {
        "common": "Guinea",
        "official": "Republic of Guinea",
        "native": {
          "fra": {
            "official": "République de Guinée",
            "common": "Guinée"
          }
        }
      },
      "iso2": "GN",
      "ccn3": "324",
      "cca3": "GIN",
      "cioc": "GUI",
      "currencies": {
        "GNF": {
          "name": "Guinean franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Conakry"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        11,
        -10
      ],
      "flag": "🇬🇳",
      "area": 245857
    },
    {
      "name": {
        "common": "Guadeloupe",
        "official": "Guadeloupe",
        "native": {
          "fra": {
            "official": "Guadeloupe",
            "common": "Guadeloupe"
          }
        }
      },
      "iso2": "GP",
      "ccn3": "312",
      "cca3": "GLP",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Basse-Terre"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        16.25,
        -61.583333
      ],
      "flag": "🇬🇵",
      "area": 1628
    },
    {
      "name": {
        "common": "Gambia",
        "official": "Republic of the Gambia",
        "native": {
          "eng": {
            "official": "Republic of the Gambia",
            "common": "Gambia"
          }
        }
      },
      "iso2": "GM",
      "ccn3": "270",
      "cca3": "GMB",
      "cioc": "GAM",
      "currencies": {
        "GMD": {
          "name": "dalasi",
          "symbol": "D"
        }
      },
      "capital": [
        "Banjul"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        13.46666666,
        -16.56666666
      ],
      "flag": "🇬🇲",
      "area": 10689
    },
    {
      "name": {
        "common": "Guinea-Bissau",
        "official": "Republic of Guinea-Bissau",
        "native": {
          "por": {
            "official": "República da Guiné-Bissau",
            "common": "Guiné-Bissau"
          },
          "pov": {
            "official": "República da Guiné-Bissau",
            "common": "Guiné-Bissau"
          }
        }
      },
      "iso2": "GW",
      "ccn3": "624",
      "cca3": "GNB",
      "cioc": "GBS",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Bissau"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "por": "Portuguese",
        "pov": "Upper Guinea Creole"
      },
      "latlng": [
        12,
        -15
      ],
      "flag": "🇬🇼",
      "area": 36125
    },
    {
      "name": {
        "common": "Equatorial Guinea",
        "official": "Republic of Equatorial Guinea",
        "native": {
          "fra": {
            "official": "République de la Guinée Équatoriale",
            "common": "Guinée équatoriale"
          },
          "por": {
            "official": "República da Guiné Equatorial",
            "common": "Guiné Equatorial"
          },
          "spa": {
            "official": "República de Guinea Ecuatorial",
            "common": "Guinea Ecuatorial"
          }
        }
      },
      "iso2": "GQ",
      "ccn3": "226",
      "cca3": "GNQ",
      "cioc": "GEQ",
      "currencies": {
        "XAF": {
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Malabo"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "fra": "French",
        "por": "Portuguese",
        "spa": "Spanish"
      },
      "latlng": [
        2,
        10
      ],
      "flag": "🇬🇶",
      "area": 28051
    },
    {
      "name": {
        "common": "Greece",
        "official": "Hellenic Republic",
        "native": {
          "ell": {
            "official": "Ελληνική Δημοκρατία",
            "common": "Ελλάδα"
          }
        }
      },
      "iso2": "GR",
      "ccn3": "300",
      "cca3": "GRC",
      "cioc": "GRE",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Athens"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "ell": "Greek"
      },
      "latlng": [
        39,
        22
      ],
      "flag": "🇬🇷",
      "area": 131990
    },
    {
      "name": {
        "common": "Grenada",
        "official": "Grenada",
        "native": {
          "eng": {
            "official": "Grenada",
            "common": "Grenada"
          }
        }
      },
      "iso2": "GD",
      "ccn3": "308",
      "cca3": "GRD",
      "cioc": "GRN",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "St. George's"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        12.11666666,
        -61.66666666
      ],
      "flag": "🇬🇩",
      "area": 344
    },
    {
      "name": {
        "common": "Greenland",
        "official": "Greenland",
        "native": {
          "kal": {
            "official": "Kalaallit Nunaat",
            "common": "Kalaallit Nunaat"
          }
        }
      },
      "iso2": "GL",
      "ccn3": "304",
      "cca3": "GRL",
      "cioc": "",
      "currencies": {
        "DKK": {
          "name": "krone",
          "symbol": "kr."
        }
      },
      "capital": [
        "Nuuk"
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "kal": "Greenlandic"
      },
      "latlng": [
        72,
        -40
      ],
      "flag": "🇬🇱",
      "area": 2166086
    },
    {
      "name": {
        "common": "Guatemala",
        "official": "Republic of Guatemala",
        "native": {
          "spa": {
            "official": "República de Guatemala",
            "common": "Guatemala"
          }
        }
      },
      "iso2": "GT",
      "ccn3": "320",
      "cca3": "GTM",
      "cioc": "GUA",
      "currencies": {
        "GTQ": {
          "name": "Guatemalan quetzal",
          "symbol": "Q"
        }
      },
      "capital": [
        "Guatemala City"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        15.5,
        -90.25
      ],
      "flag": "🇬🇹",
      "area": 108889
    },
    {
      "name": {
        "common": "French Guiana",
        "official": "Guiana",
        "native": {
          "fra": {
            "official": "Guyane",
            "common": "Guyane française"
          }
        }
      },
      "iso2": "GF",
      "ccn3": "254",
      "cca3": "GUF",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Cayenne"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        4,
        -53
      ],
      "flag": "🇬🇫",
      "area": 83534
    },
    {
      "name": {
        "common": "Guam",
        "official": "Guam",
        "native": {
          "cha": {
            "official": "Guåhån",
            "common": "Guåhån"
          },
          "eng": {
            "official": "Guam",
            "common": "Guam"
          },
          "spa": {
            "official": "Guam",
            "common": "Guam"
          }
        }
      },
      "iso2": "GU",
      "ccn3": "316",
      "cca3": "GUM",
      "cioc": "GUM",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Hagåtña"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "cha": "Chamorro",
        "eng": "English",
        "spa": "Spanish"
      },
      "latlng": [
        13.46666666,
        144.78333333
      ],
      "flag": "🇬🇺",
      "area": 549
    },
    {
      "name": {
        "common": "Guyana",
        "official": "Co-operative Republic of Guyana",
        "native": {
          "eng": {
            "official": "Co-operative Republic of Guyana",
            "common": "Guyana"
          }
        }
      },
      "iso2": "GY",
      "ccn3": "328",
      "cca3": "GUY",
      "cioc": "GUY",
      "currencies": {
        "GYD": {
          "name": "Guyanese dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Georgetown"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        5,
        -59
      ],
      "flag": "🇬🇾",
      "area": 214969
    },
    {
      "name": {
        "common": "Hong Kong",
        "official": "Hong Kong Special Administrative Region of the People's Republic of China",
        "native": {
          "eng": {
            "official": "Hong Kong Special Administrative Region of the People's Republic of China",
            "common": "Hong Kong"
          },
          "zho": {
            "official": "中华人民共和国香港特别行政区",
            "common": "香港"
          }
        }
      },
      "iso2": "HK",
      "ccn3": "344",
      "cca3": "HKG",
      "cioc": "HKG",
      "currencies": {
        "HKD": {
          "name": "Hong Kong dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "City of Victoria"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "eng": "English",
        "zho": "Chinese"
      },
      "latlng": [
        22.267,
        114.188
      ],
      "flag": "🇭🇰",
      "area": 1104
    },
    {
      "name": {
        "common": "Heard Island and McDonald Islands",
        "official": "Heard Island and McDonald Islands",
        "native": {
          "eng": {
            "official": "Heard Island and McDonald Islands",
            "common": "Heard Island and McDonald Islands"
          }
        }
      },
      "iso2": "HM",
      "ccn3": "334",
      "cca3": "HMD",
      "cioc": "",
      "currencies": [],
      "capital": [
        ""
      ],
      "region": "Antarctic",
      "subregion": "",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -53.1,
        72.51666666
      ],
      "flag": "🇭🇲",
      "area": 412
    },
    {
      "name": {
        "common": "Honduras",
        "official": "Republic of Honduras",
        "native": {
          "spa": {
            "official": "República de Honduras",
            "common": "Honduras"
          }
        }
      },
      "iso2": "HN",
      "ccn3": "340",
      "cca3": "HND",
      "cioc": "HON",
      "currencies": {
        "HNL": {
          "name": "Honduran lempira",
          "symbol": "L"
        }
      },
      "capital": [
        "Tegucigalpa"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        15,
        -86.5
      ],
      "flag": "🇭🇳",
      "area": 112492
    },
    {
      "name": {
        "common": "Croatia",
        "official": "Republic of Croatia",
        "native": {
          "hrv": {
            "official": "Republika Hrvatska",
            "common": "Hrvatska"
          }
        }
      },
      "iso2": "HR",
      "ccn3": "191",
      "cca3": "HRV",
      "cioc": "CRO",
      "currencies": {
        "HRK": {
          "name": "Croatian kuna",
          "symbol": "kn"
        }
      },
      "capital": [
        "Zagreb"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "hrv": "Croatian"
      },
      "latlng": [
        45.16666666,
        15.5
      ],
      "flag": "🇭🇷",
      "area": 56594
    },
    {
      "name": {
        "common": "Haiti",
        "official": "Republic of Haiti",
        "native": {
          "fra": {
            "official": "République d'Haïti",
            "common": "Haïti"
          },
          "hat": {
            "official": "Repiblik Ayiti",
            "common": "Ayiti"
          }
        }
      },
      "iso2": "HT",
      "ccn3": "332",
      "cca3": "HTI",
      "cioc": "HAI",
      "currencies": {
        "HTG": {
          "name": "Haitian gourde",
          "symbol": "G"
        }
      },
      "capital": [
        "Port-au-Prince"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "fra": "French",
        "hat": "Haitian Creole"
      },
      "latlng": [
        19,
        -72.41666666
      ],
      "flag": "🇭🇹",
      "area": 27750
    },
    {
      "name": {
        "common": "Hungary",
        "official": "Hungary",
        "native": {
          "hun": {
            "official": "Magyarország",
            "common": "Magyarország"
          }
        }
      },
      "iso2": "HU",
      "ccn3": "348",
      "cca3": "HUN",
      "cioc": "HUN",
      "currencies": {
        "HUF": {
          "name": "Hungarian forint",
          "symbol": "Ft"
        }
      },
      "capital": [
        "Budapest"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "languages": {
        "hun": "Hungarian"
      },
      "latlng": [
        47,
        20
      ],
      "flag": "🇭🇺",
      "area": 93028
    },
    {
      "name": {
        "common": "Indonesia",
        "official": "Republic of Indonesia",
        "native": {
          "ind": {
            "official": "Republik Indonesia",
            "common": "Indonesia"
          }
        }
      },
      "iso2": "ID",
      "ccn3": "360",
      "cca3": "IDN",
      "cioc": "INA",
      "currencies": {
        "IDR": {
          "name": "Indonesian rupiah",
          "symbol": "Rp"
        }
      },
      "capital": [
        "Jakarta"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "ind": "Indonesian"
      },
      "latlng": [
        -5,
        120
      ],
      "flag": "🇮🇩",
      "area": 1904569
    },
    {
      "name": {
        "common": "Isle of Man",
        "official": "Isle of Man",
        "native": {
          "eng": {
            "official": "Isle of Man",
            "common": "Isle of Man"
          },
          "glv": {
            "official": "Ellan Vannin or Mannin",
            "common": "Mannin"
          }
        }
      },
      "iso2": "IM",
      "ccn3": "833",
      "cca3": "IMN",
      "cioc": "",
      "currencies": {
        "GBP": {
          "name": "British pound",
          "symbol": "£"
        },
        "IMP": {
          "name": "Manx pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Douglas"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "eng": "English",
        "glv": "Manx"
      },
      "latlng": [
        54.25,
        -4.5
      ],
      "flag": "🇮🇲",
      "area": 572
    },
    {
      "name": {
        "common": "India",
        "official": "Republic of India",
        "native": {
          "eng": {
            "official": "Republic of India",
            "common": "India"
          },
          "hin": {
            "official": "भारत गणराज्य",
            "common": "भारत"
          },
          "tam": {
            "official": "இந்தியக் குடியரசு",
            "common": "இந்தியா"
          }
        }
      },
      "iso2": "IN",
      "ccn3": "356",
      "cca3": "IND",
      "cioc": "IND",
      "currencies": {
        "INR": {
          "name": "Indian rupee",
          "symbol": "₹"
        }
      },
      "capital": [
        "New Delhi"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "eng": "English",
        "hin": "Hindi",
        "tam": "Tamil"
      },
      "latlng": [
        20,
        77
      ],
      "flag": "🇮🇳",
      "area": 3287590
    },
    {
      "name": {
        "common": "British Indian Ocean Territory",
        "official": "British Indian Ocean Territory",
        "native": {
          "eng": {
            "official": "British Indian Ocean Territory",
            "common": "British Indian Ocean Territory"
          }
        }
      },
      "iso2": "IO",
      "ccn3": "086",
      "cca3": "IOT",
      "cioc": "",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Diego Garcia"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -6,
        71.5
      ],
      "flag": "🇮🇴",
      "area": 60
    },
    {
      "name": {
        "common": "Ireland",
        "official": "Republic of Ireland",
        "native": {
          "eng": {
            "official": "Republic of Ireland",
            "common": "Ireland"
          },
          "gle": {
            "official": "Poblacht na hÉireann",
            "common": "Éire"
          }
        }
      },
      "iso2": "IE",
      "ccn3": "372",
      "cca3": "IRL",
      "cioc": "IRL",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Dublin"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "eng": "English",
        "gle": "Irish"
      },
      "latlng": [
        53,
        -8
      ],
      "flag": "🇮🇪",
      "area": 70273
    },
    {
      "name": {
        "common": "Iran",
        "official": "Islamic Republic of Iran",
        "native": {
          "fas": {
            "official": "جمهوری اسلامی ایران",
            "common": "ایران"
          }
        }
      },
      "iso2": "IR",
      "ccn3": "364",
      "cca3": "IRN",
      "cioc": "IRI",
      "currencies": {
        "IRR": {
          "name": "Iranian rial",
          "symbol": "﷼"
        }
      },
      "capital": [
        "Tehran"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "fas": "Persian (Farsi)"
      },
      "latlng": [
        32,
        53
      ],
      "flag": "🇮🇷",
      "area": 1648195
    },
    {
      "name": {
        "common": "Iraq",
        "official": "Republic of Iraq",
        "native": {
          "ara": {
            "official": "جمهورية العراق",
            "common": "العراق"
          },
          "arc": {
            "official": "ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ",
            "common": "ܩܘܼܛܢܵܐ"
          },
          "ckb": {
            "official": "کۆماری عێراق",
            "common": "کۆماری"
          }
        }
      },
      "iso2": "IQ",
      "ccn3": "368",
      "cca3": "IRQ",
      "cioc": "IRQ",
      "currencies": {
        "IQD": {
          "name": "Iraqi dinar",
          "symbol": "ع.د"
        }
      },
      "capital": [
        "Baghdad"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic",
        "arc": "Aramaic",
        "ckb": "Sorani"
      },
      "latlng": [
        33,
        44
      ],
      "flag": "🇮🇶",
      "area": 438317
    },
    {
      "name": {
        "common": "Iceland",
        "official": "Iceland",
        "native": {
          "isl": {
            "official": "Ísland",
            "common": "Ísland"
          }
        }
      },
      "iso2": "IS",
      "ccn3": "352",
      "cca3": "ISL",
      "cioc": "ISL",
      "currencies": {
        "ISK": {
          "name": "Icelandic króna",
          "symbol": "kr"
        }
      },
      "capital": [
        "Reykjavik"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "isl": "Icelandic"
      },
      "latlng": [
        65,
        -18
      ],
      "flag": "🇮🇸",
      "area": 103000
    },
    {
      "name": {
        "common": "Israel",
        "official": "State of Israel",
        "native": {
          "ara": {
            "official": "دولة إسرائيل",
            "common": "إسرائيل"
          },
          "heb": {
            "official": "מדינת ישראל",
            "common": "ישראל"
          }
        }
      },
      "iso2": "IL",
      "ccn3": "376",
      "cca3": "ISR",
      "cioc": "ISR",
      "currencies": {
        "ILS": {
          "name": "Israeli new shekel",
          "symbol": "₪"
        }
      },
      "capital": [
        "Jerusalem"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic",
        "heb": "Hebrew"
      },
      "latlng": [
        31.47,
        35.13
      ],
      "flag": "🇮🇱",
      "area": 20770
    },
    {
      "name": {
        "common": "Italy",
        "official": "Italian Republic",
        "native": {
          "ita": {
            "official": "Repubblica italiana",
            "common": "Italia"
          }
        }
      },
      "iso2": "IT",
      "ccn3": "380",
      "cca3": "ITA",
      "cioc": "ITA",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Rome"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "ita": "Italian"
      },
      "latlng": [
        42.83333333,
        12.83333333
      ],
      "flag": "🇮🇹",
      "area": 301336
    },
    {
      "name": {
        "common": "Jamaica",
        "official": "Jamaica",
        "native": {
          "eng": {
            "official": "Jamaica",
            "common": "Jamaica"
          },
          "jam": {
            "official": "Jamaica",
            "common": "Jamaica"
          }
        }
      },
      "iso2": "JM",
      "ccn3": "388",
      "cca3": "JAM",
      "cioc": "JAM",
      "currencies": {
        "JMD": {
          "name": "Jamaican dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Kingston"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English",
        "jam": "Jamaican Patois"
      },
      "latlng": [
        18.25,
        -77.5
      ],
      "flag": "🇯🇲",
      "area": 10991
    },
    {
      "name": {
        "common": "Jersey",
        "official": "Bailiwick of Jersey",
        "native": {
          "eng": {
            "official": "Bailiwick of Jersey",
            "common": "Jersey"
          },
          "fra": {
            "official": "Bailliage de Jersey",
            "common": "Jersey"
          },
          "nrf": {
            "official": "Bailliage dé Jèrri",
            "common": "Jèrri"
          }
        }
      },
      "iso2": "JE",
      "ccn3": "832",
      "cca3": "JEY",
      "cioc": "",
      "currencies": {
        "GBP": {
          "name": "British pound",
          "symbol": "£"
        },
        "JEP": {
          "name": "Jersey pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Saint Helier"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "eng": "English",
        "fra": "French",
        "nrf": "Jèrriais"
      },
      "latlng": [
        49.25,
        -2.16666666
      ],
      "flag": "🇯🇪",
      "area": 116
    },
    {
      "name": {
        "common": "Jordan",
        "official": "Hashemite Kingdom of Jordan",
        "native": {
          "ara": {
            "official": "المملكة الأردنية الهاشمية",
            "common": "الأردن"
          }
        }
      },
      "iso2": "JO",
      "ccn3": "400",
      "cca3": "JOR",
      "cioc": "JOR",
      "currencies": {
        "JOD": {
          "name": "Jordanian dinar",
          "symbol": "د.ا"
        }
      },
      "capital": [
        "Amman"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        31,
        36
      ],
      "flag": "🇯🇴",
      "area": 89342
    },
    {
      "name": {
        "common": "Japan",
        "official": "Japan",
        "native": {
          "jpn": {
            "official": "日本",
            "common": "日本"
          }
        }
      },
      "iso2": "JP",
      "ccn3": "392",
      "cca3": "JPN",
      "cioc": "JPN",
      "currencies": {
        "JPY": {
          "name": "Japanese yen",
          "symbol": "¥"
        }
      },
      "capital": [
        "Tokyo"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "jpn": "Japanese"
      },
      "latlng": [
        36,
        138
      ],
      "flag": "🇯🇵",
      "area": 377930
    },
    {
      "name": {
        "common": "Kazakhstan",
        "official": "Republic of Kazakhstan",
        "native": {
          "kaz": {
            "official": "Қазақстан Республикасы",
            "common": "Қазақстан"
          },
          "rus": {
            "official": "Республика Казахстан",
            "common": "Казахстан"
          }
        }
      },
      "iso2": "KZ",
      "ccn3": "398",
      "cca3": "KAZ",
      "cioc": "KAZ",
      "currencies": {
        "KZT": {
          "name": "Kazakhstani tenge",
          "symbol": "₸"
        }
      },
      "capital": [
        "Nur-Sultan"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "languages": {
        "kaz": "Kazakh",
        "rus": "Russian"
      },
      "latlng": [
        48,
        68
      ],
      "flag": "🇰🇿",
      "area": 2724900
    },
    {
      "name": {
        "common": "Kenya",
        "official": "Republic of Kenya",
        "native": {
          "eng": {
            "official": "Republic of Kenya",
            "common": "Kenya"
          },
          "swa": {
            "official": "Republic of Kenya",
            "common": "Kenya"
          }
        }
      },
      "iso2": "KE",
      "ccn3": "404",
      "cca3": "KEN",
      "cioc": "KEN",
      "currencies": {
        "KES": {
          "name": "Kenyan shilling",
          "symbol": "Sh"
        }
      },
      "capital": [
        "Nairobi"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English",
        "swa": "Swahili"
      },
      "latlng": [
        1,
        38
      ],
      "flag": "🇰🇪",
      "area": 580367
    },
    {
      "name": {
        "common": "Kyrgyzstan",
        "official": "Kyrgyz Republic",
        "native": {
          "kir": {
            "official": "Кыргыз Республикасы",
            "common": "Кыргызстан"
          },
          "rus": {
            "official": "Кыргызская Республика",
            "common": "Киргизия"
          }
        }
      },
      "iso2": "KG",
      "ccn3": "417",
      "cca3": "KGZ",
      "cioc": "KGZ",
      "currencies": {
        "KGS": {
          "name": "Kyrgyzstani som",
          "symbol": "с"
        }
      },
      "capital": [
        "Bishkek"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "languages": {
        "kir": "Kyrgyz",
        "rus": "Russian"
      },
      "latlng": [
        41,
        75
      ],
      "flag": "🇰🇬",
      "area": 199951
    },
    {
      "name": {
        "common": "Cambodia",
        "official": "Kingdom of Cambodia",
        "native": {
          "khm": {
            "official": "ព្រះរាជាណាចក្រកម្ពុជា",
            "common": "Kâmpŭchéa"
          }
        }
      },
      "iso2": "KH",
      "ccn3": "116",
      "cca3": "KHM",
      "cioc": "CAM",
      "currencies": {
        "KHR": {
          "name": "Cambodian riel",
          "symbol": "៛"
        },
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Phnom Penh"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "khm": "Khmer"
      },
      "latlng": [
        13,
        105
      ],
      "flag": "🇰🇭",
      "area": 181035
    },
    {
      "name": {
        "common": "Kiribati",
        "official": "Independent and Sovereign Republic of Kiribati",
        "native": {
          "eng": {
            "official": "Independent and Sovereign Republic of Kiribati",
            "common": "Kiribati"
          },
          "gil": {
            "official": "Ribaberiki Kiribati",
            "common": "Kiribati"
          }
        }
      },
      "iso2": "KI",
      "ccn3": "296",
      "cca3": "KIR",
      "cioc": "KIR",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        },
        "KID": {
          "name": "Kiribati dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "South Tarawa"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "eng": "English",
        "gil": "Gilbertese"
      },
      "latlng": [
        1.41666666,
        173
      ],
      "flag": "🇰🇮",
      "area": 811
    },
    {
      "name": {
        "common": "Saint Kitts and Nevis",
        "official": "Federation of Saint Christopher and Nevis",
        "native": {
          "eng": {
            "official": "Federation of Saint Christopher and Nevis",
            "common": "Saint Kitts and Nevis"
          }
        }
      },
      "iso2": "KN",
      "ccn3": "659",
      "cca3": "KNA",
      "cioc": "SKN",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Basseterre"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        17.33333333,
        -62.75
      ],
      "flag": "🇰🇳",
      "area": 261
    },
    {
      "name": {
        "common": "South Korea",
        "official": "Republic of Korea",
        "native": {
          "kor": {
            "official": "대한민국",
            "common": "한국"
          }
        }
      },
      "iso2": "KR",
      "ccn3": "410",
      "cca3": "KOR",
      "cioc": "KOR",
      "currencies": {
        "KRW": {
          "name": "South Korean won",
          "symbol": "₩"
        }
      },
      "capital": [
        "Seoul"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "kor": "Korean"
      },
      "latlng": [
        37,
        127.5
      ],
      "flag": "🇰🇷",
      "area": 100210
    },
    {
      "name": {
        "common": "Kosovo",
        "official": "Republic of Kosovo",
        "native": {
          "sqi": {
            "official": "Republika e Kosovës",
            "common": "Kosova"
          },
          "srp": {
            "official": "Република Косово",
            "common": "Косово"
          }
        }
      },
      "iso2": "XK",
      "ccn3": "",
      "cca3": "UNK",
      "cioc": "KOS",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Pristina"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "sqi": "Albanian",
        "srp": "Serbian"
      },
      "latlng": [
        42.666667,
        21.166667
      ],
      "flag": "🇽🇰",
      "area": 10908
    },
    {
      "name": {
        "common": "Kuwait",
        "official": "State of Kuwait",
        "native": {
          "ara": {
            "official": "دولة الكويت",
            "common": "الكويت"
          }
        }
      },
      "iso2": "KW",
      "ccn3": "414",
      "cca3": "KWT",
      "cioc": "KUW",
      "currencies": {
        "KWD": {
          "name": "Kuwaiti dinar",
          "symbol": "د.ك"
        }
      },
      "capital": [
        "Kuwait City"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        29.5,
        45.75
      ],
      "flag": "🇰🇼",
      "area": 17818
    },
    {
      "name": {
        "common": "Laos",
        "official": "Lao People's Democratic Republic",
        "native": {
          "lao": {
            "official": "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
            "common": "ສປປລາວ"
          }
        }
      },
      "iso2": "LA",
      "ccn3": "418",
      "cca3": "LAO",
      "cioc": "LAO",
      "currencies": {
        "LAK": {
          "name": "Lao kip",
          "symbol": "₭"
        }
      },
      "capital": [
        "Vientiane"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "lao": "Lao"
      },
      "latlng": [
        18,
        105
      ],
      "flag": "🇱🇦",
      "area": 236800
    },
    {
      "name": {
        "common": "Lebanon",
        "official": "Lebanese Republic",
        "native": {
          "ara": {
            "official": "الجمهورية اللبنانية",
            "common": "لبنان"
          },
          "fra": {
            "official": "République libanaise",
            "common": "Liban"
          }
        }
      },
      "iso2": "LB",
      "ccn3": "422",
      "cca3": "LBN",
      "cioc": "LIB",
      "currencies": {
        "LBP": {
          "name": "Lebanese pound",
          "symbol": "ل.ل"
        }
      },
      "capital": [
        "Beirut"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic",
        "fra": "French"
      },
      "latlng": [
        33.83333333,
        35.83333333
      ],
      "flag": "🇱🇧",
      "area": 10452
    },
    {
      "name": {
        "common": "Liberia",
        "official": "Republic of Liberia",
        "native": {
          "eng": {
            "official": "Republic of Liberia",
            "common": "Liberia"
          }
        }
      },
      "iso2": "LR",
      "ccn3": "430",
      "cca3": "LBR",
      "cioc": "LBR",
      "currencies": {
        "LRD": {
          "name": "Liberian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Monrovia"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        6.5,
        -9.5
      ],
      "flag": "🇱🇷",
      "area": 111369
    },
    {
      "name": {
        "common": "Libya",
        "official": "State of Libya",
        "native": {
          "ara": {
            "official": "الدولة ليبيا",
            "common": "‏ليبيا"
          }
        }
      },
      "iso2": "LY",
      "ccn3": "434",
      "cca3": "LBY",
      "cioc": "LBA",
      "currencies": {
        "LYD": {
          "name": "Libyan dinar",
          "symbol": "ل.د"
        }
      },
      "capital": [
        "Tripoli"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        25,
        17
      ],
      "flag": "🇱🇾",
      "area": 1759540
    },
    {
      "name": {
        "common": "Saint Lucia",
        "official": "Saint Lucia",
        "native": {
          "eng": {
            "official": "Saint Lucia",
            "common": "Saint Lucia"
          }
        }
      },
      "iso2": "LC",
      "ccn3": "662",
      "cca3": "LCA",
      "cioc": "LCA",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Castries"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        13.88333333,
        -60.96666666
      ],
      "flag": "🇱🇨",
      "area": 616
    },
    {
      "name": {
        "common": "Liechtenstein",
        "official": "Principality of Liechtenstein",
        "native": {
          "deu": {
            "official": "Fürstentum Liechtenstein",
            "common": "Liechtenstein"
          }
        }
      },
      "iso2": "LI",
      "ccn3": "438",
      "cca3": "LIE",
      "cioc": "LIE",
      "currencies": {
        "CHF": {
          "name": "Swiss franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Vaduz"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "deu": "German"
      },
      "latlng": [
        47.26666666,
        9.53333333
      ],
      "flag": "🇱🇮",
      "area": 160
    },
    {
      "name": {
        "common": "Sri Lanka",
        "official": "Democratic Socialist Republic of Sri Lanka",
        "native": {
          "sin": {
            "official": "ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය",
            "common": "ශ්‍රී ලංකාව"
          },
          "tam": {
            "official": "இலங்கை சனநாயக சோசலிசக் குடியரசு",
            "common": "இலங்கை"
          }
        }
      },
      "iso2": "LK",
      "ccn3": "144",
      "cca3": "LKA",
      "cioc": "SRI",
      "currencies": {
        "LKR": {
          "name": "Sri Lankan rupee",
          "symbol": "Rs  රු"
        }
      },
      "capital": [
        "Colombo"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "sin": "Sinhala",
        "tam": "Tamil"
      },
      "latlng": [
        7,
        81
      ],
      "flag": "🇱🇰",
      "area": 65610
    },
    {
      "name": {
        "common": "Lesotho",
        "official": "Kingdom of Lesotho",
        "native": {
          "eng": {
            "official": "Kingdom of Lesotho",
            "common": "Lesotho"
          },
          "sot": {
            "official": "Kingdom of Lesotho",
            "common": "Lesotho"
          }
        }
      },
      "iso2": "LS",
      "ccn3": "426",
      "cca3": "LSO",
      "cioc": "LES",
      "currencies": {
        "LSL": {
          "name": "Lesotho loti",
          "symbol": "L"
        },
        "ZAR": {
          "name": "South African rand",
          "symbol": "R"
        }
      },
      "capital": [
        "Maseru"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "languages": {
        "eng": "English",
        "sot": "Sotho"
      },
      "latlng": [
        -29.5,
        28.5
      ],
      "flag": "🇱🇸",
      "area": 30355
    },
    {
      "name": {
        "common": "Lithuania",
        "official": "Republic of Lithuania",
        "native": {
          "lit": {
            "official": "Lietuvos Respublikos",
            "common": "Lietuva"
          }
        }
      },
      "iso2": "LT",
      "ccn3": "440",
      "cca3": "LTU",
      "cioc": "LTU",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Vilnius"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "lit": "Lithuanian"
      },
      "latlng": [
        56,
        24
      ],
      "flag": "🇱🇹",
      "area": 65300
    },
    {
      "name": {
        "common": "Luxembourg",
        "official": "Grand Duchy of Luxembourg",
        "native": {
          "deu": {
            "official": "Großherzogtum Luxemburg",
            "common": "Luxemburg"
          },
          "fra": {
            "official": "Grand-Duché de Luxembourg",
            "common": "Luxembourg"
          },
          "ltz": {
            "official": "Groussherzogtum Lëtzebuerg",
            "common": "Lëtzebuerg"
          }
        }
      },
      "iso2": "LU",
      "ccn3": "442",
      "cca3": "LUX",
      "cioc": "LUX",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Luxembourg"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "deu": "German",
        "fra": "French",
        "ltz": "Luxembourgish"
      },
      "latlng": [
        49.75,
        6.16666666
      ],
      "flag": "🇱🇺",
      "area": 2586
    },
    {
      "name": {
        "common": "Latvia",
        "official": "Republic of Latvia",
        "native": {
          "lav": {
            "official": "Latvijas Republikas",
            "common": "Latvija"
          }
        }
      },
      "iso2": "LV",
      "ccn3": "428",
      "cca3": "LVA",
      "cioc": "LAT",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Riga"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "lav": "Latvian"
      },
      "latlng": [
        57,
        25
      ],
      "flag": "🇱🇻",
      "area": 64559
    },
    {
      "name": {
        "common": "Macau",
        "official": "Macao Special Administrative Region of the People's Republic of China",
        "native": {
          "por": {
            "official": "Região Administrativa Especial de Macau da República Popular da China",
            "common": "Macau"
          },
          "zho": {
            "official": "中华人民共和国澳门特别行政区",
            "common": "澳门"
          }
        }
      },
      "iso2": "MO",
      "ccn3": "446",
      "cca3": "MAC",
      "cioc": "",
      "currencies": {
        "MOP": {
          "name": "Macanese pataca",
          "symbol": "P"
        }
      },
      "capital": [
        ""
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "por": "Portuguese",
        "zho": "Chinese"
      },
      "latlng": [
        22.16666666,
        113.55
      ],
      "flag": "🇲🇴",
      "area": 30
    },
    {
      "name": {
        "common": "Saint Martin",
        "official": "Saint Martin",
        "native": {
          "fra": {
            "official": "Saint-Martin",
            "common": "Saint-Martin"
          }
        }
      },
      "iso2": "MF",
      "ccn3": "663",
      "cca3": "MAF",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Marigot"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        18.08333333,
        -63.95
      ],
      "flag": "🇲🇫",
      "area": 53
    },
    {
      "name": {
        "common": "Morocco",
        "official": "Kingdom of Morocco",
        "native": {
          "ara": {
            "official": "المملكة المغربية",
            "common": "المغرب"
          },
          "ber": {
            "official": "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ",
            "common": "ⵍⵎⴰⵖⵔⵉⴱ"
          }
        }
      },
      "iso2": "MA",
      "ccn3": "504",
      "cca3": "MAR",
      "cioc": "MAR",
      "currencies": {
        "MAD": {
          "name": "Moroccan dirham",
          "symbol": "د.م."
        }
      },
      "capital": [
        "Rabat"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ara": "Arabic",
        "ber": "Berber"
      },
      "latlng": [
        32,
        -5
      ],
      "flag": "🇲🇦",
      "area": 446550
    },
    {
      "name": {
        "common": "Monaco",
        "official": "Principality of Monaco",
        "native": {
          "fra": {
            "official": "Principauté de Monaco",
            "common": "Monaco"
          }
        }
      },
      "iso2": "MC",
      "ccn3": "492",
      "cca3": "MCO",
      "cioc": "MON",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Monaco"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        43.73333333,
        7.4
      ],
      "flag": "🇲🇨",
      "area": 2.02
    },
    {
      "name": {
        "common": "Moldova",
        "official": "Republic of Moldova",
        "native": {
          "ron": {
            "official": "Republica Moldova",
            "common": "Moldova"
          }
        }
      },
      "iso2": "MD",
      "ccn3": "498",
      "cca3": "MDA",
      "cioc": "MDA",
      "currencies": {
        "MDL": {
          "name": "Moldovan leu",
          "symbol": "L"
        }
      },
      "capital": [
        "Chișinău"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "languages": {
        "ron": "Moldavian"
      },
      "latlng": [
        47,
        29
      ],
      "flag": "🇲🇩",
      "area": 33846
    },
    {
      "name": {
        "common": "Madagascar",
        "official": "Republic of Madagascar",
        "native": {
          "fra": {
            "official": "République de Madagascar",
            "common": "Madagascar"
          },
          "mlg": {
            "official": "Repoblikan'i Madagasikara",
            "common": "Madagasikara"
          }
        }
      },
      "iso2": "MG",
      "ccn3": "450",
      "cca3": "MDG",
      "cioc": "MAD",
      "currencies": {
        "MGA": {
          "name": "Malagasy ariary",
          "symbol": "Ar"
        }
      },
      "capital": [
        "Antananarivo"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "fra": "French",
        "mlg": "Malagasy"
      },
      "latlng": [
        -20,
        47
      ],
      "flag": "🇲🇬",
      "area": 587041
    },
    {
      "name": {
        "common": "Maldives",
        "official": "Republic of the Maldives",
        "native": {
          "div": {
            "official": "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
            "common": "ދިވެހިރާއްޖޭގެ"
          }
        }
      },
      "iso2": "MV",
      "ccn3": "462",
      "cca3": "MDV",
      "cioc": "MDV",
      "currencies": {
        "MVR": {
          "name": "Maldivian rufiyaa",
          "symbol": ".ރ"
        }
      },
      "capital": [
        "Malé"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "div": "Maldivian"
      },
      "latlng": [
        3.25,
        73
      ],
      "flag": "🇲🇻",
      "area": 300
    },
    {
      "name": {
        "common": "Mexico",
        "official": "United Mexican States",
        "native": {
          "spa": {
            "official": "Estados Unidos Mexicanos",
            "common": "México"
          }
        }
      },
      "iso2": "MX",
      "ccn3": "484",
      "cca3": "MEX",
      "cioc": "MEX",
      "currencies": {
        "MXN": {
          "name": "Mexican peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Mexico City"
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        23,
        -102
      ],
      "flag": "🇲🇽",
      "area": 1964375
    },
    {
      "name": {
        "common": "Marshall Islands",
        "official": "Republic of the Marshall Islands",
        "native": {
          "eng": {
            "official": "Republic of the Marshall Islands",
            "common": "Marshall Islands"
          },
          "mah": {
            "official": "Republic of the Marshall Islands",
            "common": "M̧ajeļ"
          }
        }
      },
      "iso2": "MH",
      "ccn3": "584",
      "cca3": "MHL",
      "cioc": "MHL",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Majuro"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "eng": "English",
        "mah": "Marshallese"
      },
      "latlng": [
        9,
        168
      ],
      "flag": "🇲🇭",
      "area": 181
    },
    {
      "name": {
        "common": "North Macedonia",
        "official": "Republic of North Macedonia",
        "native": {
          "mkd": {
            "official": "Република Северна Македонија",
            "common": "Македонија"
          }
        }
      },
      "iso2": "MK",
      "ccn3": "807",
      "cca3": "MKD",
      "cioc": "MKD",
      "currencies": {
        "MKD": {
          "name": "denar",
          "symbol": "den"
        }
      },
      "capital": [
        "Skopje"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "mkd": "Macedonian"
      },
      "latlng": [
        41.83333333,
        22
      ],
      "flag": "🇲🇰",
      "area": 25713
    },
    {
      "name": {
        "common": "Mali",
        "official": "Republic of Mali",
        "native": {
          "fra": {
            "official": "République du Mali",
            "common": "Mali"
          }
        }
      },
      "iso2": "ML",
      "ccn3": "466",
      "cca3": "MLI",
      "cioc": "MLI",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Bamako"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        17,
        -4
      ],
      "flag": "🇲🇱",
      "area": 1240192
    },
    {
      "name": {
        "common": "Malta",
        "official": "Republic of Malta",
        "native": {
          "eng": {
            "official": "Republic of Malta",
            "common": "Malta"
          },
          "mlt": {
            "official": "Repubblika ta ' Malta",
            "common": "Malta"
          }
        }
      },
      "iso2": "MT",
      "ccn3": "470",
      "cca3": "MLT",
      "cioc": "MLT",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Valletta"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "eng": "English",
        "mlt": "Maltese"
      },
      "latlng": [
        35.83333333,
        14.58333333
      ],
      "flag": "🇲🇹",
      "area": 316
    },
    {
      "name": {
        "common": "Myanmar",
        "official": "Republic of the Union of Myanmar",
        "native": {
          "mya": {
            "official": "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်",
            "common": "မြန်မာ"
          }
        }
      },
      "iso2": "MM",
      "ccn3": "104",
      "cca3": "MMR",
      "cioc": "MYA",
      "currencies": {
        "MMK": {
          "name": "Burmese kyat",
          "symbol": "Ks"
        }
      },
      "capital": [
        "Naypyidaw"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "mya": "Burmese"
      },
      "latlng": [
        22,
        98
      ],
      "flag": "🇲🇲",
      "area": 676578
    },
    {
      "name": {
        "common": "Montenegro",
        "official": "Montenegro",
        "native": {
          "cnr": {
            "official": "Црна Гора",
            "common": "Црна Гора"
          }
        }
      },
      "iso2": "ME",
      "ccn3": "499",
      "cca3": "MNE",
      "cioc": "MNE",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Podgorica"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "cnr": "Montenegrin"
      },
      "latlng": [
        42.5,
        19.3
      ],
      "flag": "🇲🇪",
      "area": 13812
    },
    {
      "name": {
        "common": "Mongolia",
        "official": "Mongolia",
        "native": {
          "mon": {
            "official": "Монгол улс",
            "common": "Монгол улс"
          }
        }
      },
      "iso2": "MN",
      "ccn3": "496",
      "cca3": "MNG",
      "cioc": "MGL",
      "currencies": {
        "MNT": {
          "name": "Mongolian tögrög",
          "symbol": "₮"
        }
      },
      "capital": [
        "Ulan Bator"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "mon": "Mongolian"
      },
      "latlng": [
        46,
        105
      ],
      "flag": "🇲🇳",
      "area": 1564110
    },
    {
      "name": {
        "common": "Northern Mariana Islands",
        "official": "Commonwealth of the Northern Mariana Islands",
        "native": {
          "cal": {
            "official": "Commonwealth of the Northern Mariana Islands",
            "common": "Northern Mariana Islands"
          },
          "cha": {
            "official": "Sankattan Siha Na Islas Mariånas",
            "common": "Na Islas Mariånas"
          },
          "eng": {
            "official": "Commonwealth of the Northern Mariana Islands",
            "common": "Northern Mariana Islands"
          }
        }
      },
      "iso2": "MP",
      "ccn3": "580",
      "cca3": "MNP",
      "cioc": "",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Saipan"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "cal": "Carolinian",
        "cha": "Chamorro",
        "eng": "English"
      },
      "latlng": [
        15.2,
        145.75
      ],
      "flag": "🇲🇵",
      "area": 464
    },
    {
      "name": {
        "common": "Mozambique",
        "official": "Republic of Mozambique",
        "native": {
          "por": {
            "official": "República de Moçambique",
            "common": "Moçambique"
          }
        }
      },
      "iso2": "MZ",
      "ccn3": "508",
      "cca3": "MOZ",
      "cioc": "MOZ",
      "currencies": {
        "MZN": {
          "name": "Mozambican metical",
          "symbol": "MT"
        }
      },
      "capital": [
        "Maputo"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "por": "Portuguese"
      },
      "latlng": [
        -18.25,
        35
      ],
      "flag": "🇲🇿",
      "area": 801590
    },
    {
      "name": {
        "common": "Mauritania",
        "official": "Islamic Republic of Mauritania",
        "native": {
          "ara": {
            "official": "الجمهورية الإسلامية الموريتانية",
            "common": "موريتانيا"
          }
        }
      },
      "iso2": "MR",
      "ccn3": "478",
      "cca3": "MRT",
      "cioc": "MTN",
      "currencies": {
        "MRU": {
          "name": "Mauritanian ouguiya",
          "symbol": "UM"
        }
      },
      "capital": [
        "Nouakchott"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        20,
        -12
      ],
      "flag": "🇲🇷",
      "area": 1030700
    },
    {
      "name": {
        "common": "Montserrat",
        "official": "Montserrat",
        "native": {
          "eng": {
            "official": "Montserrat",
            "common": "Montserrat"
          }
        }
      },
      "iso2": "MS",
      "ccn3": "500",
      "cca3": "MSR",
      "cioc": "",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Plymouth"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        16.75,
        -62.2
      ],
      "flag": "🇲🇸",
      "area": 102
    },
    {
      "name": {
        "common": "Martinique",
        "official": "Martinique",
        "native": {
          "fra": {
            "official": "Martinique",
            "common": "Martinique"
          }
        }
      },
      "iso2": "MQ",
      "ccn3": "474",
      "cca3": "MTQ",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Fort-de-France"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        14.666667,
        -61
      ],
      "flag": "🇲🇶",
      "area": 1128
    },
    {
      "name": {
        "common": "Mauritius",
        "official": "Republic of Mauritius",
        "native": {
          "eng": {
            "official": "Republic of Mauritius",
            "common": "Mauritius"
          },
          "fra": {
            "official": "République de Maurice",
            "common": "Maurice"
          },
          "mfe": {
            "official": "Republik Moris",
            "common": "Moris"
          }
        }
      },
      "iso2": "MU",
      "ccn3": "480",
      "cca3": "MUS",
      "cioc": "MRI",
      "currencies": {
        "MUR": {
          "name": "Mauritian rupee",
          "symbol": "₨"
        }
      },
      "capital": [
        "Port Louis"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English",
        "fra": "French",
        "mfe": "Mauritian Creole"
      },
      "latlng": [
        -20.28333333,
        57.55
      ],
      "flag": "🇲🇺",
      "area": 2040
    },
    {
      "name": {
        "common": "Malawi",
        "official": "Republic of Malawi",
        "native": {
          "eng": {
            "official": "Republic of Malawi",
            "common": "Malawi"
          },
          "nya": {
            "official": "Chalo cha Malawi, Dziko la Malaŵi",
            "common": "Malaŵi"
          }
        }
      },
      "iso2": "MW",
      "ccn3": "454",
      "cca3": "MWI",
      "cioc": "MAW",
      "currencies": {
        "MWK": {
          "name": "Malawian kwacha",
          "symbol": "MK"
        }
      },
      "capital": [
        "Lilongwe"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English",
        "nya": "Chewa"
      },
      "latlng": [
        -13.5,
        34
      ],
      "flag": "🇲🇼",
      "area": 118484
    },
    {
      "name": {
        "common": "Malaysia",
        "official": "Malaysia",
        "native": {
          "eng": {
            "official": "Malaysia",
            "common": "Malaysia"
          },
          "msa": {
            "official": "مليسيا",
            "common": "مليسيا"
          }
        }
      },
      "iso2": "MY",
      "ccn3": "458",
      "cca3": "MYS",
      "cioc": "MAS",
      "currencies": {
        "MYR": {
          "name": "Malaysian ringgit",
          "symbol": "RM"
        }
      },
      "capital": [
        "Kuala Lumpur"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "eng": "English",
        "msa": "Malay"
      },
      "latlng": [
        2.5,
        112.5
      ],
      "flag": "🇲🇾",
      "area": 330803
    },
    {
      "name": {
        "common": "Mayotte",
        "official": "Department of Mayotte",
        "native": {
          "fra": {
            "official": "Département de Mayotte",
            "common": "Mayotte"
          }
        }
      },
      "iso2": "YT",
      "ccn3": "175",
      "cca3": "MYT",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Mamoudzou"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -12.83333333,
        45.16666666
      ],
      "flag": "🇾🇹",
      "area": 374
    },
    {
      "name": {
        "common": "Namibia",
        "official": "Republic of Namibia",
        "native": {
          "afr": {
            "official": "Republiek van Namibië",
            "common": "Namibië"
          },
          "deu": {
            "official": "Republik Namibia",
            "common": "Namibia"
          },
          "eng": {
            "official": "Republic of Namibia",
            "common": "Namibia"
          },
          "her": {
            "official": "Republic of Namibia",
            "common": "Namibia"
          },
          "hgm": {
            "official": "Republic of Namibia",
            "common": "Namibia"
          },
          "kwn": {
            "official": "Republic of Namibia",
            "common": "Namibia"
          },
          "loz": {
            "official": "Republic of Namibia",
            "common": "Namibia"
          },
          "ndo": {
            "official": "Republic of Namibia",
            "common": "Namibia"
          },
          "tsn": {
            "official": "Lefatshe la Namibia",
            "common": "Namibia"
          }
        }
      },
      "iso2": "NA",
      "ccn3": "516",
      "cca3": "NAM",
      "cioc": "NAM",
      "currencies": {
        "NAD": {
          "name": "Namibian dollar",
          "symbol": "$"
        },
        "ZAR": {
          "name": "South African rand",
          "symbol": "R"
        }
      },
      "capital": [
        "Windhoek"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "languages": {
        "afr": "Afrikaans",
        "deu": "German",
        "eng": "English",
        "her": "Herero",
        "hgm": "Khoekhoe",
        "kwn": "Kwangali",
        "loz": "Lozi",
        "ndo": "Ndonga",
        "tsn": "Tswana"
      },
      "latlng": [
        -22,
        17
      ],
      "flag": "🇳🇦",
      "area": 825615
    },
    {
      "name": {
        "common": "New Caledonia",
        "official": "New Caledonia",
        "native": {
          "fra": {
            "official": "Nouvelle-Calédonie",
            "common": "Nouvelle-Calédonie"
          }
        }
      },
      "iso2": "NC",
      "ccn3": "540",
      "cca3": "NCL",
      "cioc": "",
      "currencies": {
        "XPF": {
          "name": "CFP franc",
          "symbol": "₣"
        }
      },
      "capital": [
        "Nouméa"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -21.5,
        165.5
      ],
      "flag": "🇳🇨",
      "area": 18575
    },
    {
      "name": {
        "common": "Niger",
        "official": "Republic of Niger",
        "native": {
          "fra": {
            "official": "République du Niger",
            "common": "Niger"
          }
        }
      },
      "iso2": "NE",
      "ccn3": "562",
      "cca3": "NER",
      "cioc": "NIG",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Niamey"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        16,
        8
      ],
      "flag": "🇳🇪",
      "area": 1267000
    },
    {
      "name": {
        "common": "Norfolk Island",
        "official": "Territory of Norfolk Island",
        "native": {
          "eng": {
            "official": "Territory of Norfolk Island",
            "common": "Norfolk Island"
          },
          "pih": {
            "official": "Teratri of Norf'k Ailen",
            "common": "Norf'k Ailen"
          }
        }
      },
      "iso2": "NF",
      "ccn3": "574",
      "cca3": "NFK",
      "cioc": "",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Kingston"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "languages": {
        "eng": "English",
        "pih": "Norfuk"
      },
      "latlng": [
        -29.03333333,
        167.95
      ],
      "flag": "🇳🇫",
      "area": 36
    },
    {
      "name": {
        "common": "Nigeria",
        "official": "Federal Republic of Nigeria",
        "native": {
          "eng": {
            "official": "Federal Republic of Nigeria",
            "common": "Nigeria"
          }
        }
      },
      "iso2": "NG",
      "ccn3": "566",
      "cca3": "NGA",
      "cioc": "NGR",
      "currencies": {
        "NGN": {
          "name": "Nigerian naira",
          "symbol": "₦"
        }
      },
      "capital": [
        "Abuja"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        10,
        8
      ],
      "flag": "🇳🇬",
      "area": 923768
    },
    {
      "name": {
        "common": "Nicaragua",
        "official": "Republic of Nicaragua",
        "native": {
          "spa": {
            "official": "República de Nicaragua",
            "common": "Nicaragua"
          }
        }
      },
      "iso2": "NI",
      "ccn3": "558",
      "cca3": "NIC",
      "cioc": "NCA",
      "currencies": {
        "NIO": {
          "name": "Nicaraguan córdoba",
          "symbol": "C$"
        }
      },
      "capital": [
        "Managua"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        13,
        -85
      ],
      "flag": "🇳🇮",
      "area": 130373
    },
    {
      "name": {
        "common": "Niue",
        "official": "Niue",
        "native": {
          "eng": {
            "official": "Niue",
            "common": "Niue"
          },
          "niu": {
            "official": "Niuē",
            "common": "Niuē"
          }
        }
      },
      "iso2": "NU",
      "ccn3": "570",
      "cca3": "NIU",
      "cioc": "",
      "currencies": {
        "NZD": {
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Alofi"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "niu": "Niuean"
      },
      "latlng": [
        -19.03333333,
        -169.86666666
      ],
      "flag": "🇳🇺",
      "area": 260
    },
    {
      "name": {
        "common": "Netherlands",
        "official": "Kingdom of the Netherlands",
        "native": {
          "nld": {
            "official": "Koninkrijk der Nederlanden",
            "common": "Nederland"
          }
        }
      },
      "iso2": "NL",
      "ccn3": "528",
      "cca3": "NLD",
      "cioc": "NED",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Amsterdam"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "languages": {
        "nld": "Dutch"
      },
      "latlng": [
        52.5,
        5.75
      ],
      "flag": "🇳🇱",
      "area": 41850
    },
    {
      "name": {
        "common": "Norway",
        "official": "Kingdom of Norway",
        "native": {
          "nno": {
            "official": "Kongeriket Noreg",
            "common": "Noreg"
          },
          "nob": {
            "official": "Kongeriket Norge",
            "common": "Norge"
          },
          "smi": {
            "official": "Norgga gonagasriika",
            "common": "Norgga"
          }
        }
      },
      "iso2": "NO",
      "ccn3": "578",
      "cca3": "NOR",
      "cioc": "NOR",
      "currencies": {
        "NOK": {
          "name": "Norwegian krone",
          "symbol": "kr"
        }
      },
      "capital": [
        "Oslo"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "nno": "Norwegian Nynorsk",
        "nob": "Norwegian Bokmål",
        "smi": "Sami"
      },
      "latlng": [
        62,
        10
      ],
      "flag": "🇳🇴",
      "area": 323802
    },
    {
      "name": {
        "common": "Nepal",
        "official": "Federal Democratic Republic of Nepal",
        "native": {
          "nep": {
            "official": "नेपाल संघीय लोकतान्त्रिक गणतन्त्र",
            "common": "नेपाल"
          }
        }
      },
      "iso2": "NP",
      "ccn3": "524",
      "cca3": "NPL",
      "cioc": "NEP",
      "currencies": {
        "NPR": {
          "name": "Nepalese rupee",
          "symbol": "₨"
        }
      },
      "capital": [
        "Kathmandu"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "nep": "Nepali"
      },
      "latlng": [
        28,
        84
      ],
      "flag": "🇳🇵",
      "area": 147181
    },
    {
      "name": {
        "common": "Nauru",
        "official": "Republic of Nauru",
        "native": {
          "eng": {
            "official": "Republic of Nauru",
            "common": "Nauru"
          },
          "nau": {
            "official": "Republic of Nauru",
            "common": "Nauru"
          }
        }
      },
      "iso2": "NR",
      "ccn3": "520",
      "cca3": "NRU",
      "cioc": "NRU",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Yaren"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "eng": "English",
        "nau": "Nauru"
      },
      "latlng": [
        -0.53333333,
        166.91666666
      ],
      "flag": "🇳🇷",
      "area": 21
    },
    {
      "name": {
        "common": "New Zealand",
        "official": "New Zealand",
        "native": {
          "eng": {
            "official": "New Zealand",
            "common": "New Zealand"
          },
          "mri": {
            "official": "Aotearoa",
            "common": "Aotearoa"
          },
          "nzs": {
            "official": "New Zealand",
            "common": "New Zealand"
          }
        }
      },
      "iso2": "NZ",
      "ccn3": "554",
      "cca3": "NZL",
      "cioc": "NZL",
      "currencies": {
        "NZD": {
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Wellington"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "languages": {
        "eng": "English",
        "mri": "Māori",
        "nzs": "New Zealand Sign Language"
      },
      "latlng": [
        -41,
        174
      ],
      "flag": "🇳🇿",
      "area": 270467
    },
    {
      "name": {
        "common": "Oman",
        "official": "Sultanate of Oman",
        "native": {
          "ara": {
            "official": "سلطنة عمان",
            "common": "عمان"
          }
        }
      },
      "iso2": "OM",
      "ccn3": "512",
      "cca3": "OMN",
      "cioc": "OMA",
      "currencies": {
        "OMR": {
          "name": "Omani rial",
          "symbol": "ر.ع."
        }
      },
      "capital": [
        "Muscat"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        21,
        57
      ],
      "flag": "🇴🇲",
      "area": 309500
    },
    {
      "name": {
        "common": "Pakistan",
        "official": "Islamic Republic of Pakistan",
        "native": {
          "eng": {
            "official": "Islamic Republic of Pakistan",
            "common": "Pakistan"
          },
          "urd": {
            "official": "اسلامی جمہوریۂ پاكستان",
            "common": "پاكستان"
          }
        }
      },
      "iso2": "PK",
      "ccn3": "586",
      "cca3": "PAK",
      "cioc": "PAK",
      "currencies": {
        "PKR": {
          "name": "Pakistani rupee",
          "symbol": "₨"
        }
      },
      "capital": [
        "Islamabad"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "languages": {
        "eng": "English",
        "urd": "Urdu"
      },
      "latlng": [
        30,
        70
      ],
      "flag": "🇵🇰",
      "area": 881912
    },
    {
      "name": {
        "common": "Panama",
        "official": "Republic of Panama",
        "native": {
          "spa": {
            "official": "República de Panamá",
            "common": "Panamá"
          }
        }
      },
      "iso2": "PA",
      "ccn3": "591",
      "cca3": "PAN",
      "cioc": "PAN",
      "currencies": {
        "PAB": {
          "name": "Panamanian balboa",
          "symbol": "B/."
        },
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Panama City"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        9,
        -80
      ],
      "flag": "🇵🇦",
      "area": 75417
    },
    {
      "name": {
        "common": "Pitcairn Islands",
        "official": "Pitcairn Group of Islands",
        "native": {
          "eng": {
            "official": "Pitcairn Group of Islands",
            "common": "Pitcairn Islands"
          }
        }
      },
      "iso2": "PN",
      "ccn3": "612",
      "cca3": "PCN",
      "cioc": "",
      "currencies": {
        "NZD": {
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Adamstown"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -25.06666666,
        -130.1
      ],
      "flag": "🇵🇳",
      "area": 47
    },
    {
      "name": {
        "common": "Peru",
        "official": "Republic of Peru",
        "native": {
          "aym": {
            "official": "Piruw Suyu",
            "common": "Piruw"
          },
          "que": {
            "official": "Piruw Ripuwlika",
            "common": "Piruw"
          },
          "spa": {
            "official": "República del Perú",
            "common": "Perú"
          }
        }
      },
      "iso2": "PE",
      "ccn3": "604",
      "cca3": "PER",
      "cioc": "PER",
      "currencies": {
        "PEN": {
          "name": "Peruvian sol",
          "symbol": "S/."
        }
      },
      "capital": [
        "Lima"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "aym": "Aymara",
        "que": "Quechua",
        "spa": "Spanish"
      },
      "latlng": [
        -10,
        -76
      ],
      "flag": "🇵🇪",
      "area": 1285216
    },
    {
      "name": {
        "common": "Philippines",
        "official": "Republic of the Philippines",
        "native": {
          "eng": {
            "official": "Republic of the Philippines",
            "common": "Philippines"
          },
          "fil": {
            "official": "Republic of the Philippines",
            "common": "Pilipinas"
          }
        }
      },
      "iso2": "PH",
      "ccn3": "608",
      "cca3": "PHL",
      "cioc": "PHI",
      "currencies": {
        "PHP": {
          "name": "Philippine peso",
          "symbol": "₱"
        }
      },
      "capital": [
        "Manila"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "eng": "English",
        "fil": "Filipino"
      },
      "latlng": [
        13,
        122
      ],
      "flag": "🇵🇭",
      "area": 342353
    },
    {
      "name": {
        "common": "Palau",
        "official": "Republic of Palau",
        "native": {
          "eng": {
            "official": "Republic of Palau",
            "common": "Palau"
          },
          "pau": {
            "official": "Beluu er a Belau",
            "common": "Belau"
          }
        }
      },
      "iso2": "PW",
      "ccn3": "585",
      "cca3": "PLW",
      "cioc": "PLW",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Ngerulmud"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "languages": {
        "eng": "English",
        "pau": "Palauan"
      },
      "latlng": [
        7.5,
        134.5
      ],
      "flag": "🇵🇼",
      "area": 459
    },
    {
      "name": {
        "common": "Papua New Guinea",
        "official": "Independent State of Papua New Guinea",
        "native": {
          "eng": {
            "official": "Independent State of Papua New Guinea",
            "common": "Papua New Guinea"
          },
          "hmo": {
            "official": "Independen Stet bilong Papua Niugini",
            "common": "Papua Niu Gini"
          },
          "tpi": {
            "official": "Independen Stet bilong Papua Niugini",
            "common": "Papua Niugini"
          }
        }
      },
      "iso2": "PG",
      "ccn3": "598",
      "cca3": "PNG",
      "cioc": "PNG",
      "currencies": {
        "PGK": {
          "name": "Papua New Guinean kina",
          "symbol": "K"
        }
      },
      "capital": [
        "Port Moresby"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "languages": {
        "eng": "English",
        "hmo": "Hiri Motu",
        "tpi": "Tok Pisin"
      },
      "latlng": [
        -6,
        147
      ],
      "flag": "🇵🇬",
      "area": 462840
    },
    {
      "name": {
        "common": "Poland",
        "official": "Republic of Poland",
        "native": {
          "pol": {
            "official": "Rzeczpospolita Polska",
            "common": "Polska"
          }
        }
      },
      "iso2": "PL",
      "ccn3": "616",
      "cca3": "POL",
      "cioc": "POL",
      "currencies": {
        "PLN": {
          "name": "Polish złoty",
          "symbol": "zł"
        }
      },
      "capital": [
        "Warsaw"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "languages": {
        "pol": "Polish"
      },
      "latlng": [
        52,
        20
      ],
      "flag": "🇵🇱",
      "area": 312679
    },
    {
      "name": {
        "common": "Puerto Rico",
        "official": "Commonwealth of Puerto Rico",
        "native": {
          "eng": {
            "official": "Commonwealth of Puerto Rico",
            "common": "Puerto Rico"
          },
          "spa": {
            "official": "Estado Libre Asociado de Puerto Rico",
            "common": "Puerto Rico"
          }
        }
      },
      "iso2": "PR",
      "ccn3": "630",
      "cca3": "PRI",
      "cioc": "PUR",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "San Juan"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English",
        "spa": "Spanish"
      },
      "latlng": [
        18.25,
        -66.5
      ],
      "flag": "🇵🇷",
      "area": 8870
    },
    {
      "name": {
        "common": "North Korea",
        "official": "Democratic People's Republic of Korea",
        "native": {
          "kor": {
            "official": "조선민주주의인민공화국",
            "common": "조선"
          }
        }
      },
      "iso2": "KP",
      "ccn3": "408",
      "cca3": "PRK",
      "cioc": "PRK",
      "currencies": {
        "KPW": {
          "name": "North Korean won",
          "symbol": "₩"
        }
      },
      "capital": [
        "Pyongyang"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "kor": "Korean"
      },
      "latlng": [
        40,
        127
      ],
      "flag": "🇰🇵",
      "area": 120538
    },
    {
      "name": {
        "common": "Portugal",
        "official": "Portuguese Republic",
        "native": {
          "por": {
            "official": "República português",
            "common": "Portugal"
          }
        }
      },
      "iso2": "PT",
      "ccn3": "620",
      "cca3": "PRT",
      "cioc": "POR",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Lisbon"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "por": "Portuguese"
      },
      "latlng": [
        39.5,
        -8
      ],
      "flag": "🇵🇹",
      "area": 92090
    },
    {
      "name": {
        "common": "Paraguay",
        "official": "Republic of Paraguay",
        "native": {
          "grn": {
            "official": "Tetã Paraguái",
            "common": "Paraguái"
          },
          "spa": {
            "official": "República de Paraguay",
            "common": "Paraguay"
          }
        }
      },
      "iso2": "PY",
      "ccn3": "600",
      "cca3": "PRY",
      "cioc": "PAR",
      "currencies": {
        "PYG": {
          "name": "Paraguayan guaraní",
          "symbol": "₲"
        }
      },
      "capital": [
        "Asunción"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "grn": "Guaraní",
        "spa": "Spanish"
      },
      "latlng": [
        -23,
        -58
      ],
      "flag": "🇵🇾",
      "area": 406752
    },
    {
      "name": {
        "common": "Palestine",
        "official": "State of Palestine",
        "native": {
          "ara": {
            "official": "دولة فلسطين",
            "common": "فلسطين"
          }
        }
      },
      "iso2": "PS",
      "ccn3": "275",
      "cca3": "PSE",
      "cioc": "PLE",
      "currencies": {
        "EGP": {
          "name": "Egyptian pound",
          "symbol": "E£"
        },
        "ILS": {
          "name": "Israeli new shekel",
          "symbol": "₪"
        },
        "JOD": {
          "name": "Jordanian dinar",
          "symbol": "JD"
        }
      },
      "capital": [
        "Ramallah"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        31.9,
        35.2
      ],
      "flag": "🇵🇸",
      "area": 6220
    },
    {
      "name": {
        "common": "French Polynesia",
        "official": "French Polynesia",
        "native": {
          "fra": {
            "official": "Polynésie française",
            "common": "Polynésie française"
          }
        }
      },
      "iso2": "PF",
      "ccn3": "258",
      "cca3": "PYF",
      "cioc": "",
      "currencies": {
        "XPF": {
          "name": "CFP franc",
          "symbol": "₣"
        }
      },
      "capital": [
        "Papeetē"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -15,
        -140
      ],
      "flag": "🇵🇫",
      "area": 4167
    },
    {
      "name": {
        "common": "Qatar",
        "official": "State of Qatar",
        "native": {
          "ara": {
            "official": "دولة قطر",
            "common": "قطر"
          }
        }
      },
      "iso2": "QA",
      "ccn3": "634",
      "cca3": "QAT",
      "cioc": "QAT",
      "currencies": {
        "QAR": {
          "name": "Qatari riyal",
          "symbol": "ر.ق"
        }
      },
      "capital": [
        "Doha"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        25.5,
        51.25
      ],
      "flag": "🇶🇦",
      "area": 11586
    },
    {
      "name": {
        "common": "Réunion",
        "official": "Réunion Island",
        "native": {
          "fra": {
            "official": "Ile de la Réunion",
            "common": "La Réunion"
          }
        }
      },
      "iso2": "RE",
      "ccn3": "638",
      "cca3": "REU",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Saint-Denis"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -21.15,
        55.5
      ],
      "flag": "🇷🇪",
      "area": 2511
    },
    {
      "name": {
        "common": "Romania",
        "official": "Romania",
        "native": {
          "ron": {
            "official": "România",
            "common": "România"
          }
        }
      },
      "iso2": "RO",
      "ccn3": "642",
      "cca3": "ROU",
      "cioc": "ROU",
      "currencies": {
        "RON": {
          "name": "Romanian leu",
          "symbol": "lei"
        }
      },
      "capital": [
        "Bucharest"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "ron": "Romanian"
      },
      "latlng": [
        46,
        25
      ],
      "flag": "🇷🇴",
      "area": 238391
    },
    {
      "name": {
        "common": "Russia",
        "official": "Russian Federation",
        "native": {
          "rus": {
            "official": "Российская Федерация",
            "common": "Россия"
          }
        }
      },
      "iso2": "RU",
      "ccn3": "643",
      "cca3": "RUS",
      "cioc": "RUS",
      "currencies": {
        "RUB": {
          "name": "Russian ruble",
          "symbol": "₽"
        }
      },
      "capital": [
        "Moscow"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "languages": {
        "rus": "Russian"
      },
      "latlng": [
        60,
        100
      ],
      "flag": "🇷🇺",
      "area": 17098242
    },
    {
      "name": {
        "common": "Rwanda",
        "official": "Republic of Rwanda",
        "native": {
          "eng": {
            "official": "Republic of Rwanda",
            "common": "Rwanda"
          },
          "fra": {
            "official": "République rwandaise",
            "common": "Rwanda"
          },
          "kin": {
            "official": "Repubulika y'u Rwanda",
            "common": "Rwanda"
          }
        }
      },
      "iso2": "RW",
      "ccn3": "646",
      "cca3": "RWA",
      "cioc": "RWA",
      "currencies": {
        "RWF": {
          "name": "Rwandan franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Kigali"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English",
        "fra": "French",
        "kin": "Kinyarwanda"
      },
      "latlng": [
        -2,
        30
      ],
      "flag": "🇷🇼",
      "area": 26338
    },
    {
      "name": {
        "common": "Saudi Arabia",
        "official": "Kingdom of Saudi Arabia",
        "native": {
          "ara": {
            "official": "المملكة العربية السعودية",
            "common": "العربية السعودية"
          }
        }
      },
      "iso2": "SA",
      "ccn3": "682",
      "cca3": "SAU",
      "cioc": "KSA",
      "currencies": {
        "SAR": {
          "name": "Saudi riyal",
          "symbol": "ر.س"
        }
      },
      "capital": [
        "Riyadh"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        25,
        45
      ],
      "flag": "🇸🇦",
      "area": 2149690
    },
    {
      "name": {
        "common": "Sudan",
        "official": "Republic of the Sudan",
        "native": {
          "ara": {
            "official": "جمهورية السودان",
            "common": "السودان"
          },
          "eng": {
            "official": "Republic of the Sudan",
            "common": "Sudan"
          }
        }
      },
      "iso2": "SD",
      "ccn3": "729",
      "cca3": "SDN",
      "cioc": "SUD",
      "currencies": {
        "SDG": {
          "name": "Sudanese pound",
          "symbol": ""
        }
      },
      "capital": [
        "Khartoum"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ara": "Arabic",
        "eng": "English"
      },
      "latlng": [
        15,
        30
      ],
      "flag": "🇸🇩",
      "area": 1886068
    },
    {
      "name": {
        "common": "Senegal",
        "official": "Republic of Senegal",
        "native": {
          "fra": {
            "official": "République du Sénégal",
            "common": "Sénégal"
          }
        }
      },
      "iso2": "SN",
      "ccn3": "686",
      "cca3": "SEN",
      "cioc": "SEN",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Dakar"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        14,
        -14
      ],
      "flag": "🇸🇳",
      "area": 196722
    },
    {
      "name": {
        "common": "Singapore",
        "official": "Republic of Singapore",
        "native": {
          "zho": {
            "official": "新加坡共和国",
            "common": "新加坡"
          },
          "eng": {
            "official": "Republic of Singapore",
            "common": "Singapore"
          },
          "msa": {
            "official": "Republik Singapura",
            "common": "Singapura"
          },
          "tam": {
            "official": "சிங்கப்பூர் குடியரசு",
            "common": "சிங்கப்பூர்"
          }
        }
      },
      "iso2": "SG",
      "ccn3": "702",
      "cca3": "SGP",
      "cioc": "SIN",
      "currencies": {
        "SGD": {
          "name": "Singapore dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Singapore"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "zho": "Chinese",
        "eng": "English",
        "msa": "Malay",
        "tam": "Tamil"
      },
      "latlng": [
        1.36666666,
        103.8
      ],
      "flag": "🇸🇬",
      "area": 710
    },
    {
      "name": {
        "common": "South Georgia",
        "official": "South Georgia and the South Sandwich Islands",
        "native": {
          "eng": {
            "official": "South Georgia and the South Sandwich Islands",
            "common": "South Georgia"
          }
        }
      },
      "iso2": "GS",
      "ccn3": "239",
      "cca3": "SGS",
      "cioc": "",
      "currencies": {
        "SHP": {
          "name": "Saint Helena pound",
          "symbol": "£"
        }
      },
      "capital": [
        "King Edward Point"
      ],
      "region": "Antarctic",
      "subregion": "",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -54.5,
        -37
      ],
      "flag": "🇬🇸",
      "area": 3903
    },
    {
      "name": {
        "common": "Svalbard and Jan Mayen",
        "official": "Svalbard og Jan Mayen",
        "native": {
          "nor": {
            "official": "Svalbard og Jan Mayen",
            "common": "Svalbard og Jan Mayen"
          }
        }
      },
      "iso2": "SJ",
      "ccn3": "744",
      "cca3": "SJM",
      "cioc": "",
      "currencies": {
        "NOK": {
          "name": "krone",
          "symbol": "kr"
        }
      },
      "capital": [
        "Longyearbyen"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "nor": "Norwegian"
      },
      "latlng": [
        78,
        20
      ],
      "flag": "🇸🇯",
      "area": -1
    },
    {
      "name": {
        "common": "Solomon Islands",
        "official": "Solomon Islands",
        "native": {
          "eng": {
            "official": "Solomon Islands",
            "common": "Solomon Islands"
          }
        }
      },
      "iso2": "SB",
      "ccn3": "090",
      "cca3": "SLB",
      "cioc": "SOL",
      "currencies": {
        "SBD": {
          "name": "Solomon Islands dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Honiara"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -8,
        159
      ],
      "flag": "🇸🇧",
      "area": 28896
    },
    {
      "name": {
        "common": "Sierra Leone",
        "official": "Republic of Sierra Leone",
        "native": {
          "eng": {
            "official": "Republic of Sierra Leone",
            "common": "Sierra Leone"
          }
        }
      },
      "iso2": "SL",
      "ccn3": "694",
      "cca3": "SLE",
      "cioc": "SLE",
      "currencies": {
        "SLL": {
          "name": "Sierra Leonean leone",
          "symbol": "Le"
        }
      },
      "capital": [
        "Freetown"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        8.5,
        -11.5
      ],
      "flag": "🇸🇱",
      "area": 71740
    },
    {
      "name": {
        "common": "El Salvador",
        "official": "Republic of El Salvador",
        "native": {
          "spa": {
            "official": "República de El Salvador",
            "common": "El Salvador"
          }
        }
      },
      "iso2": "SV",
      "ccn3": "222",
      "cca3": "SLV",
      "cioc": "ESA",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "San Salvador"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        13.83333333,
        -88.91666666
      ],
      "flag": "🇸🇻",
      "area": 21041
    },
    {
      "name": {
        "common": "San Marino",
        "official": "Most Serene Republic of San Marino",
        "native": {
          "ita": {
            "official": "Serenissima Repubblica di San Marino",
            "common": "San Marino"
          }
        }
      },
      "iso2": "SM",
      "ccn3": "674",
      "cca3": "SMR",
      "cioc": "SMR",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "City of San Marino"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "ita": "Italian"
      },
      "latlng": [
        43.76666666,
        12.41666666
      ],
      "flag": "🇸🇲",
      "area": 61
    },
    {
      "name": {
        "common": "Somalia",
        "official": "Federal Republic of Somalia",
        "native": {
          "ara": {
            "official": "جمهورية الصومال‎‎",
            "common": "الصومال‎‎"
          },
          "som": {
            "official": "Jamhuuriyadda Federaalka Soomaaliya",
            "common": "Soomaaliya"
          }
        }
      },
      "iso2": "SO",
      "ccn3": "706",
      "cca3": "SOM",
      "cioc": "SOM",
      "currencies": {
        "SOS": {
          "name": "Somali shilling",
          "symbol": "Sh"
        }
      },
      "capital": [
        "Mogadishu"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "ara": "Arabic",
        "som": "Somali"
      },
      "latlng": [
        10,
        49
      ],
      "flag": "🇸🇴",
      "area": 637657
    },
    {
      "name": {
        "common": "Saint Pierre and Miquelon",
        "official": "Saint Pierre and Miquelon",
        "native": {
          "fra": {
            "official": "Collectivité territoriale de Saint-Pierre-et-Miquelon",
            "common": "Saint-Pierre-et-Miquelon"
          }
        }
      },
      "iso2": "PM",
      "ccn3": "666",
      "cca3": "SPM",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Saint-Pierre"
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        46.83333333,
        -56.33333333
      ],
      "flag": "🇵🇲",
      "area": 242
    },
    {
      "name": {
        "common": "Serbia",
        "official": "Republic of Serbia",
        "native": {
          "srp": {
            "official": "Република Србија",
            "common": "Србија"
          }
        }
      },
      "iso2": "RS",
      "ccn3": "688",
      "cca3": "SRB",
      "cioc": "SRB",
      "currencies": {
        "RSD": {
          "name": "Serbian dinar",
          "symbol": "дин."
        }
      },
      "capital": [
        "Belgrade"
      ],
      "region": "Europe",
      "subregion": "Southeast Europe",
      "languages": {
        "srp": "Serbian"
      },
      "latlng": [
        44,
        21
      ],
      "flag": "🇷🇸",
      "area": 88361
    },
    {
      "name": {
        "common": "South Sudan",
        "official": "Republic of South Sudan",
        "native": {
          "eng": {
            "official": "Republic of South Sudan",
            "common": "South Sudan"
          }
        }
      },
      "iso2": "SS",
      "ccn3": "728",
      "cca3": "SSD",
      "cioc": "",
      "currencies": {
        "SSP": {
          "name": "South Sudanese pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Juba"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        7,
        30
      ],
      "flag": "🇸🇸",
      "area": 619745
    },
    {
      "name": {
        "common": "São Tomé and Príncipe",
        "official": "Democratic Republic of São Tomé and Príncipe",
        "native": {
          "por": {
            "official": "República Democrática do São Tomé e Príncipe",
            "common": "São Tomé e Príncipe"
          }
        }
      },
      "iso2": "ST",
      "ccn3": "678",
      "cca3": "STP",
      "cioc": "STP",
      "currencies": {
        "STN": {
          "name": "São Tomé and Príncipe dobra",
          "symbol": "Db"
        }
      },
      "capital": [
        "São Tomé"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "por": "Portuguese"
      },
      "latlng": [
        1,
        7
      ],
      "flag": "🇸🇹",
      "area": 964
    },
    {
      "name": {
        "common": "Suriname",
        "official": "Republic of Suriname",
        "native": {
          "nld": {
            "official": "Republiek Suriname",
            "common": "Suriname"
          }
        }
      },
      "iso2": "SR",
      "ccn3": "740",
      "cca3": "SUR",
      "cioc": "SUR",
      "currencies": {
        "SRD": {
          "name": "Surinamese dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Paramaribo"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "nld": "Dutch"
      },
      "latlng": [
        4,
        -56
      ],
      "flag": "🇸🇷",
      "area": 163820
    },
    {
      "name": {
        "common": "Slovakia",
        "official": "Slovak Republic",
        "native": {
          "slk": {
            "official": "Slovenská republika",
            "common": "Slovensko"
          }
        }
      },
      "iso2": "SK",
      "ccn3": "703",
      "cca3": "SVK",
      "cioc": "SVK",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Bratislava"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "languages": {
        "slk": "Slovak"
      },
      "latlng": [
        48.66666666,
        19.5
      ],
      "flag": "🇸🇰",
      "area": 49037
    },
    {
      "name": {
        "common": "Slovenia",
        "official": "Republic of Slovenia",
        "native": {
          "slv": {
            "official": "Republika Slovenija",
            "common": "Slovenija"
          }
        }
      },
      "iso2": "SI",
      "ccn3": "705",
      "cca3": "SVN",
      "cioc": "SLO",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Ljubljana"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "languages": {
        "slv": "Slovene"
      },
      "latlng": [
        46.11666666,
        14.81666666
      ],
      "flag": "🇸🇮",
      "area": 20273
    },
    {
      "name": {
        "common": "Sweden",
        "official": "Kingdom of Sweden",
        "native": {
          "swe": {
            "official": "Konungariket Sverige",
            "common": "Sverige"
          }
        }
      },
      "iso2": "SE",
      "ccn3": "752",
      "cca3": "SWE",
      "cioc": "SWE",
      "currencies": {
        "SEK": {
          "name": "Swedish krona",
          "symbol": "kr"
        }
      },
      "capital": [
        "Stockholm"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "languages": {
        "swe": "Swedish"
      },
      "latlng": [
        62,
        15
      ],
      "flag": "🇸🇪",
      "area": 450295
    },
    {
      "name": {
        "common": "Eswatini",
        "official": "Kingdom of Eswatini",
        "native": {
          "eng": {
            "official": "Kingdom of Eswatini",
            "common": "Eswatini"
          },
          "ssw": {
            "official": "Umbuso weSwatini",
            "common": "eSwatini"
          }
        }
      },
      "iso2": "SZ",
      "ccn3": "748",
      "cca3": "SWZ",
      "cioc": "SWZ",
      "currencies": {
        "SZL": {
          "name": "Swazi lilangeni",
          "symbol": "L"
        },
        "ZAR": {
          "name": "South African rand",
          "symbol": "R"
        }
      },
      "capital": [
        "Lobamba"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "languages": {
        "eng": "English",
        "ssw": "Swazi"
      },
      "latlng": [
        -26.5,
        31.5
      ],
      "flag": "🇸🇿",
      "area": 17364
    },
    {
      "name": {
        "common": "Sint Maarten",
        "official": "Sint Maarten",
        "native": {
          "eng": {
            "official": "Sint Maarten",
            "common": "Sint Maarten"
          },
          "fra": {
            "official": "Saint-Martin",
            "common": "Saint-Martin"
          },
          "nld": {
            "official": "Sint Maarten",
            "common": "Sint Maarten"
          }
        }
      },
      "iso2": "SX",
      "ccn3": "534",
      "cca3": "SXM",
      "cioc": "",
      "currencies": {
        "ANG": {
          "name": "Netherlands Antillean guilder",
          "symbol": "ƒ"
        }
      },
      "capital": [
        "Philipsburg"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English",
        "fra": "French",
        "nld": "Dutch"
      },
      "latlng": [
        18.033333,
        -63.05
      ],
      "flag": "🇸🇽",
      "area": 34
    },
    {
      "name": {
        "common": "Seychelles",
        "official": "Republic of Seychelles",
        "native": {
          "crs": {
            "official": "Repiblik Sesel",
            "common": "Sesel"
          },
          "eng": {
            "official": "Republic of Seychelles",
            "common": "Seychelles"
          },
          "fra": {
            "official": "République des Seychelles",
            "common": "Seychelles"
          }
        }
      },
      "iso2": "SC",
      "ccn3": "690",
      "cca3": "SYC",
      "cioc": "SEY",
      "currencies": {
        "SCR": {
          "name": "Seychellois rupee",
          "symbol": "₨"
        }
      },
      "capital": [
        "Victoria"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "crs": "Seychellois Creole",
        "eng": "English",
        "fra": "French"
      },
      "latlng": [
        -4.58333333,
        55.66666666
      ],
      "flag": "🇸🇨",
      "area": 452
    },
    {
      "name": {
        "common": "Syria",
        "official": "Syrian Arab Republic",
        "native": {
          "ara": {
            "official": "الجمهورية العربية السورية",
            "common": "سوريا"
          }
        }
      },
      "iso2": "SY",
      "ccn3": "760",
      "cca3": "SYR",
      "cioc": "SYR",
      "currencies": {
        "SYP": {
          "name": "Syrian pound",
          "symbol": "£"
        }
      },
      "capital": [
        "Damascus"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        35,
        38
      ],
      "flag": "🇸🇾",
      "area": 185180
    },
    {
      "name": {
        "common": "Turks and Caicos Islands",
        "official": "Turks and Caicos Islands",
        "native": {
          "eng": {
            "official": "Turks and Caicos Islands",
            "common": "Turks and Caicos Islands"
          }
        }
      },
      "iso2": "TC",
      "ccn3": "796",
      "cca3": "TCA",
      "cioc": "",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Cockburn Town"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        21.75,
        -71.58333333
      ],
      "flag": "🇹🇨",
      "area": 948
    },
    {
      "name": {
        "common": "Chad",
        "official": "Republic of Chad",
        "native": {
          "ara": {
            "official": "جمهورية تشاد",
            "common": "تشاد‎"
          },
          "fra": {
            "official": "République du Tchad",
            "common": "Tchad"
          }
        }
      },
      "iso2": "TD",
      "ccn3": "148",
      "cca3": "TCD",
      "cioc": "CHA",
      "currencies": {
        "XAF": {
          "name": "Central African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "N'Djamena"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "languages": {
        "ara": "Arabic",
        "fra": "French"
      },
      "latlng": [
        15,
        19
      ],
      "flag": "🇹🇩",
      "area": 1284000
    },
    {
      "name": {
        "common": "Togo",
        "official": "Togolese Republic",
        "native": {
          "fra": {
            "official": "République togolaise",
            "common": "Togo"
          }
        }
      },
      "iso2": "TG",
      "ccn3": "768",
      "cca3": "TGO",
      "cioc": "TOG",
      "currencies": {
        "XOF": {
          "name": "West African CFA franc",
          "symbol": "Fr"
        }
      },
      "capital": [
        "Lomé"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        8,
        1.16666666
      ],
      "flag": "🇹🇬",
      "area": 56785
    },
    {
      "name": {
        "common": "Thailand",
        "official": "Kingdom of Thailand",
        "native": {
          "tha": {
            "official": "ราชอาณาจักรไทย",
            "common": "ประเทศไทย"
          }
        }
      },
      "iso2": "TH",
      "ccn3": "764",
      "cca3": "THA",
      "cioc": "THA",
      "currencies": {
        "THB": {
          "name": "Thai baht",
          "symbol": "฿"
        }
      },
      "capital": [
        "Bangkok"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "tha": "Thai"
      },
      "latlng": [
        15,
        100
      ],
      "flag": "🇹🇭",
      "area": 513120
    },
    {
      "name": {
        "common": "Tajikistan",
        "official": "Republic of Tajikistan",
        "native": {
          "rus": {
            "official": "Республика Таджикистан",
            "common": "Таджикистан"
          },
          "tgk": {
            "official": "Ҷумҳурии Тоҷикистон",
            "common": "Тоҷикистон"
          }
        }
      },
      "iso2": "TJ",
      "ccn3": "762",
      "cca3": "TJK",
      "cioc": "TJK",
      "currencies": {
        "TJS": {
          "name": "Tajikistani somoni",
          "symbol": "ЅМ"
        }
      },
      "capital": [
        "Dushanbe"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "languages": {
        "rus": "Russian",
        "tgk": "Tajik"
      },
      "latlng": [
        39,
        71
      ],
      "flag": "🇹🇯",
      "area": 143100
    },
    {
      "name": {
        "common": "Tokelau",
        "official": "Tokelau",
        "native": {
          "eng": {
            "official": "Tokelau",
            "common": "Tokelau"
          },
          "smo": {
            "official": "Tokelau",
            "common": "Tokelau"
          },
          "tkl": {
            "official": "Tokelau",
            "common": "Tokelau"
          }
        }
      },
      "iso2": "TK",
      "ccn3": "772",
      "cca3": "TKL",
      "cioc": "",
      "currencies": {
        "NZD": {
          "name": "New Zealand dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Fakaofo"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "smo": "Samoan",
        "tkl": "Tokelauan"
      },
      "latlng": [
        -9,
        -172
      ],
      "flag": "🇹🇰",
      "area": 12
    },
    {
      "name": {
        "common": "Turkmenistan",
        "official": "Turkmenistan",
        "native": {
          "rus": {
            "official": "Туркменистан",
            "common": "Туркмения"
          },
          "tuk": {
            "official": "Türkmenistan",
            "common": "Türkmenistan"
          }
        }
      },
      "iso2": "TM",
      "ccn3": "795",
      "cca3": "TKM",
      "cioc": "TKM",
      "currencies": {
        "TMT": {
          "name": "Turkmenistan manat",
          "symbol": "m"
        }
      },
      "capital": [
        "Ashgabat"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "languages": {
        "rus": "Russian",
        "tuk": "Turkmen"
      },
      "latlng": [
        40,
        60
      ],
      "flag": "🇹🇲",
      "area": 488100
    },
    {
      "name": {
        "common": "Timor-Leste",
        "official": "Democratic Republic of Timor-Leste",
        "native": {
          "por": {
            "official": "República Democrática de Timor-Leste",
            "common": "Timor-Leste"
          },
          "tet": {
            "official": "Repúblika Demokrátika Timór-Leste",
            "common": "Timór-Leste"
          }
        }
      },
      "iso2": "TL",
      "ccn3": "626",
      "cca3": "TLS",
      "cioc": "TLS",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Dili"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "por": "Portuguese",
        "tet": "Tetum"
      },
      "latlng": [
        -8.83333333,
        125.91666666
      ],
      "flag": "🇹🇱",
      "area": 14874
    },
    {
      "name": {
        "common": "Tonga",
        "official": "Kingdom of Tonga",
        "native": {
          "eng": {
            "official": "Kingdom of Tonga",
            "common": "Tonga"
          },
          "ton": {
            "official": "Kingdom of Tonga",
            "common": "Tonga"
          }
        }
      },
      "iso2": "TO",
      "ccn3": "776",
      "cca3": "TON",
      "cioc": "TGA",
      "currencies": {
        "TOP": {
          "name": "Tongan paʻanga",
          "symbol": "T$"
        }
      },
      "capital": [
        "Nuku'alofa"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "ton": "Tongan"
      },
      "latlng": [
        -20,
        -175
      ],
      "flag": "🇹🇴",
      "area": 747
    },
    {
      "name": {
        "common": "Trinidad and Tobago",
        "official": "Republic of Trinidad and Tobago",
        "native": {
          "eng": {
            "official": "Republic of Trinidad and Tobago",
            "common": "Trinidad and Tobago"
          }
        }
      },
      "iso2": "TT",
      "ccn3": "780",
      "cca3": "TTO",
      "cioc": "TTO",
      "currencies": {
        "TTD": {
          "name": "Trinidad and Tobago dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Port of Spain"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        11,
        -61
      ],
      "flag": "🇹🇹",
      "area": 5130
    },
    {
      "name": {
        "common": "Tunisia",
        "official": "Tunisian Republic",
        "native": {
          "ara": {
            "official": "الجمهورية التونسية",
            "common": "تونس"
          }
        }
      },
      "iso2": "TN",
      "ccn3": "788",
      "cca3": "TUN",
      "cioc": "TUN",
      "currencies": {
        "TND": {
          "name": "Tunisian dinar",
          "symbol": "د.ت"
        }
      },
      "capital": [
        "Tunis"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        34,
        9
      ],
      "flag": "🇹🇳",
      "area": 163610
    },
    {
      "name": {
        "common": "Turkey",
        "official": "Republic of Turkey",
        "native": {
          "tur": {
            "official": "Türkiye Cumhuriyeti",
            "common": "Türkiye"
          }
        }
      },
      "iso2": "TR",
      "ccn3": "792",
      "cca3": "TUR",
      "cioc": "TUR",
      "currencies": {
        "TRY": {
          "name": "Turkish lira",
          "symbol": "₺"
        }
      },
      "capital": [
        "Ankara"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "tur": "Turkish"
      },
      "latlng": [
        39,
        35
      ],
      "flag": "🇹🇷",
      "area": 783562
    },
    {
      "name": {
        "common": "Tuvalu",
        "official": "Tuvalu",
        "native": {
          "eng": {
            "official": "Tuvalu",
            "common": "Tuvalu"
          },
          "tvl": {
            "official": "Tuvalu",
            "common": "Tuvalu"
          }
        }
      },
      "iso2": "TV",
      "ccn3": "798",
      "cca3": "TUV",
      "cioc": "TUV",
      "currencies": {
        "AUD": {
          "name": "Australian dollar",
          "symbol": "$"
        },
        "TVD": {
          "name": "Tuvaluan dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Funafuti"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "tvl": "Tuvaluan"
      },
      "latlng": [
        -8,
        178
      ],
      "flag": "🇹🇻",
      "area": 26
    },
    {
      "name": {
        "common": "Taiwan",
        "official": "Republic of China (Taiwan)",
        "native": {
          "zho": {
            "official": "中華民國",
            "common": "台灣"
          }
        }
      },
      "iso2": "TW",
      "ccn3": "158",
      "cca3": "TWN",
      "cioc": "TPE",
      "currencies": {
        "TWD": {
          "name": "New Taiwan dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Taipei"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "languages": {
        "zho": "Chinese"
      },
      "latlng": [
        23.5,
        121
      ],
      "flag": "🇹🇼",
      "area": 36193
    },
    {
      "name": {
        "common": "Tanzania",
        "official": "United Republic of Tanzania",
        "native": {
          "eng": {
            "official": "United Republic of Tanzania",
            "common": "Tanzania"
          },
          "swa": {
            "official": "Jamhuri ya Muungano wa Tanzania",
            "common": "Tanzania"
          }
        }
      },
      "iso2": "TZ",
      "ccn3": "834",
      "cca3": "TZA",
      "cioc": "TAN",
      "currencies": {
        "TZS": {
          "name": "Tanzanian shilling",
          "symbol": "Sh"
        }
      },
      "capital": [
        "Dodoma"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English",
        "swa": "Swahili"
      },
      "latlng": [
        -6,
        35
      ],
      "flag": "🇹🇿",
      "area": 945087
    },
    {
      "name": {
        "common": "Uganda",
        "official": "Republic of Uganda",
        "native": {
          "eng": {
            "official": "Republic of Uganda",
            "common": "Uganda"
          },
          "swa": {
            "official": "Republic of Uganda",
            "common": "Uganda"
          }
        }
      },
      "iso2": "UG",
      "ccn3": "800",
      "cca3": "UGA",
      "cioc": "UGA",
      "currencies": {
        "UGX": {
          "name": "Ugandan shilling",
          "symbol": "Sh"
        }
      },
      "capital": [
        "Kampala"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English",
        "swa": "Swahili"
      },
      "latlng": [
        1,
        32
      ],
      "flag": "🇺🇬",
      "area": 241550
    },
    {
      "name": {
        "common": "Ukraine",
        "official": "Ukraine",
        "native": {
          "ukr": {
            "official": "Україна",
            "common": "Україна"
          }
        }
      },
      "iso2": "UA",
      "ccn3": "804",
      "cca3": "UKR",
      "cioc": "UKR",
      "currencies": {
        "UAH": {
          "name": "Ukrainian hryvnia",
          "symbol": "₴"
        }
      },
      "capital": [
        "Kyiv"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "languages": {
        "ukr": "Ukrainian"
      },
      "latlng": [
        49,
        32
      ],
      "flag": "🇺🇦",
      "area": 603500
    },
    {
      "name": {
        "common": "United States Minor Outlying Islands",
        "official": "United States Minor Outlying Islands",
        "native": {
          "eng": {
            "official": "United States Minor Outlying Islands",
            "common": "United States Minor Outlying Islands"
          }
        }
      },
      "iso2": "UM",
      "ccn3": "581",
      "cca3": "UMI",
      "cioc": "",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        ""
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        19.3,
        166.633333
      ],
      "flag": "🇺🇲",
      "area": 34.2
    },
    {
      "name": {
        "common": "Uruguay",
        "official": "Oriental Republic of Uruguay",
        "native": {
          "spa": {
            "official": "República Oriental del Uruguay",
            "common": "Uruguay"
          }
        }
      },
      "iso2": "UY",
      "ccn3": "858",
      "cca3": "URY",
      "cioc": "URU",
      "currencies": {
        "UYU": {
          "name": "Uruguayan peso",
          "symbol": "$"
        }
      },
      "capital": [
        "Montevideo"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        -33,
        -56
      ],
      "flag": "🇺🇾",
      "area": 181034
    },
    {
      "name": {
        "common": "United States",
        "official": "United States of America",
        "native": {
          "eng": {
            "official": "United States of America",
            "common": "United States"
          }
        }
      },
      "iso2": "US",
      "ccn3": "840",
      "cca3": "USA",
      "cioc": "USA",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Washington D.C."
      ],
      "region": "Americas",
      "subregion": "North America",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        38,
        -97
      ],
      "flag": "🇺🇸",
      "area": 9372610
    },
    {
      "name": {
        "common": "Uzbekistan",
        "official": "Republic of Uzbekistan",
        "native": {
          "rus": {
            "official": "Республика Узбекистан",
            "common": "Узбекистан"
          },
          "uzb": {
            "official": "O'zbekiston Respublikasi",
            "common": "O‘zbekiston"
          }
        }
      },
      "iso2": "UZ",
      "ccn3": "860",
      "cca3": "UZB",
      "cioc": "UZB",
      "currencies": {
        "UZS": {
          "name": "Uzbekistani soʻm",
          "symbol": "so'm"
        }
      },
      "capital": [
        "Tashkent"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "languages": {
        "rus": "Russian",
        "uzb": "Uzbek"
      },
      "latlng": [
        41,
        64
      ],
      "flag": "🇺🇿",
      "area": 447400
    },
    {
      "name": {
        "common": "Vatican City",
        "official": "Vatican City State",
        "native": {
          "ita": {
            "official": "Stato della Città del Vaticano",
            "common": "Vaticano"
          },
          "lat": {
            "official": "Status Civitatis Vaticanæ",
            "common": "Vaticanæ"
          }
        }
      },
      "iso2": "VA",
      "ccn3": "336",
      "cca3": "VAT",
      "cioc": "",
      "currencies": {
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        }
      },
      "capital": [
        "Vatican City"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "languages": {
        "ita": "Italian",
        "lat": "Latin"
      },
      "latlng": [
        41.9,
        12.45
      ],
      "flag": "🇻🇦",
      "area": 0.44
    },
    {
      "name": {
        "common": "Saint Vincent and the Grenadines",
        "official": "Saint Vincent and the Grenadines",
        "native": {
          "eng": {
            "official": "Saint Vincent and the Grenadines",
            "common": "Saint Vincent and the Grenadines"
          }
        }
      },
      "iso2": "VC",
      "ccn3": "670",
      "cca3": "VCT",
      "cioc": "VIN",
      "currencies": {
        "XCD": {
          "name": "Eastern Caribbean dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Kingstown"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        13.25,
        -61.2
      ],
      "flag": "🇻🇨",
      "area": 389
    },
    {
      "name": {
        "common": "Venezuela",
        "official": "Bolivarian Republic of Venezuela",
        "native": {
          "spa": {
            "official": "República Bolivariana de Venezuela",
            "common": "Venezuela"
          }
        }
      },
      "iso2": "VE",
      "ccn3": "862",
      "cca3": "VEN",
      "cioc": "VEN",
      "currencies": {
        "VES": {
          "name": "Venezuelan bolívar soberano",
          "symbol": "Bs.S."
        }
      },
      "capital": [
        "Caracas"
      ],
      "region": "Americas",
      "subregion": "South America",
      "languages": {
        "spa": "Spanish"
      },
      "latlng": [
        8,
        -66
      ],
      "flag": "🇻🇪",
      "area": 916445
    },
    {
      "name": {
        "common": "British Virgin Islands",
        "official": "Virgin Islands",
        "native": {
          "eng": {
            "official": "Virgin Islands",
            "common": "British Virgin Islands"
          }
        }
      },
      "iso2": "VG",
      "ccn3": "092",
      "cca3": "VGB",
      "cioc": "IVB",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Road Town"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        18.431383,
        -64.62305
      ],
      "flag": "🇻🇬",
      "area": 151
    },
    {
      "name": {
        "common": "United States Virgin Islands",
        "official": "Virgin Islands of the United States",
        "native": {
          "eng": {
            "official": "Virgin Islands of the United States",
            "common": "United States Virgin Islands"
          }
        }
      },
      "iso2": "VI",
      "ccn3": "850",
      "cca3": "VIR",
      "cioc": "ISV",
      "currencies": {
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        }
      },
      "capital": [
        "Charlotte Amalie"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        18.35,
        -64.933333
      ],
      "flag": "🇻🇮",
      "area": 347
    },
    {
      "name": {
        "common": "Vietnam",
        "official": "Socialist Republic of Vietnam",
        "native": {
          "vie": {
            "official": "Cộng hòa xã hội chủ nghĩa Việt Nam",
            "common": "Việt Nam"
          }
        }
      },
      "iso2": "VN",
      "ccn3": "704",
      "cca3": "VNM",
      "cioc": "VIE",
      "currencies": {
        "VND": {
          "name": "Vietnamese đồng",
          "symbol": "₫"
        }
      },
      "capital": [
        "Hanoi"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "languages": {
        "vie": "Vietnamese"
      },
      "latlng": [
        16.16666666,
        107.83333333
      ],
      "flag": "🇻🇳",
      "area": 331212
    },
    {
      "name": {
        "common": "Vanuatu",
        "official": "Republic of Vanuatu",
        "native": {
          "bis": {
            "official": "Ripablik blong Vanuatu",
            "common": "Vanuatu"
          },
          "eng": {
            "official": "Republic of Vanuatu",
            "common": "Vanuatu"
          },
          "fra": {
            "official": "République de Vanuatu",
            "common": "Vanuatu"
          }
        }
      },
      "iso2": "VU",
      "ccn3": "548",
      "cca3": "VUT",
      "cioc": "VAN",
      "currencies": {
        "VUV": {
          "name": "Vanuatu vatu",
          "symbol": "Vt"
        }
      },
      "capital": [
        "Port Vila"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "languages": {
        "bis": "Bislama",
        "eng": "English",
        "fra": "French"
      },
      "latlng": [
        -16,
        167
      ],
      "flag": "🇻🇺",
      "area": 12189
    },
    {
      "name": {
        "common": "Wallis and Futuna",
        "official": "Territory of the Wallis and Futuna Islands",
        "native": {
          "fra": {
            "official": "Territoire des îles Wallis et Futuna",
            "common": "Wallis et Futuna"
          }
        }
      },
      "iso2": "WF",
      "ccn3": "876",
      "cca3": "WLF",
      "cioc": "",
      "currencies": {
        "XPF": {
          "name": "CFP franc",
          "symbol": "₣"
        }
      },
      "capital": [
        "Mata-Utu"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "fra": "French"
      },
      "latlng": [
        -13.3,
        -176.2
      ],
      "flag": "🇼🇫",
      "area": 142
    },
    {
      "name": {
        "common": "Samoa",
        "official": "Independent State of Samoa",
        "native": {
          "eng": {
            "official": "Independent State of Samoa",
            "common": "Samoa"
          },
          "smo": {
            "official": "Malo Saʻoloto Tutoʻatasi o Sāmoa",
            "common": "Sāmoa"
          }
        }
      },
      "iso2": "WS",
      "ccn3": "882",
      "cca3": "WSM",
      "cioc": "SAM",
      "currencies": {
        "WST": {
          "name": "Samoan tālā",
          "symbol": "T"
        }
      },
      "capital": [
        "Apia"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "languages": {
        "eng": "English",
        "smo": "Samoan"
      },
      "latlng": [
        -13.58333333,
        -172.33333333
      ],
      "flag": "🇼🇸",
      "area": 2842
    },
    {
      "name": {
        "common": "Yemen",
        "official": "Republic of Yemen",
        "native": {
          "ara": {
            "official": "الجمهورية اليمنية",
            "common": "اليَمَن"
          }
        }
      },
      "iso2": "YE",
      "ccn3": "887",
      "cca3": "YEM",
      "cioc": "YEM",
      "currencies": {
        "YER": {
          "name": "Yemeni rial",
          "symbol": "﷼"
        }
      },
      "capital": [
        "Sana'a"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "languages": {
        "ara": "Arabic"
      },
      "latlng": [
        15,
        48
      ],
      "flag": "🇾🇪",
      "area": 527968
    },
    {
      "name": {
        "common": "South Africa",
        "official": "Republic of South Africa",
        "native": {
          "afr": {
            "official": "Republiek van Suid-Afrika",
            "common": "South Africa"
          },
          "eng": {
            "official": "Republic of South Africa",
            "common": "South Africa"
          },
          "nbl": {
            "official": "IRiphabliki yeSewula Afrika",
            "common": "Sewula Afrika"
          },
          "nso": {
            "official": "Rephaboliki ya Afrika-Borwa ",
            "common": "Afrika-Borwa"
          },
          "sot": {
            "official": "Rephaboliki ya Afrika Borwa",
            "common": "Afrika Borwa"
          },
          "ssw": {
            "official": "IRiphabhulikhi yeNingizimu Afrika",
            "common": "Ningizimu Afrika"
          },
          "tsn": {
            "official": "Rephaboliki ya Aforika Borwa",
            "common": "Aforika Borwa"
          },
          "tso": {
            "official": "Riphabliki ra Afrika Dzonga",
            "common": "Afrika Dzonga"
          },
          "ven": {
            "official": "Riphabuḽiki ya Afurika Tshipembe",
            "common": "Afurika Tshipembe"
          },
          "xho": {
            "official": "IRiphabliki yaseMzantsi Afrika",
            "common": "Mzantsi Afrika"
          },
          "zul": {
            "official": "IRiphabliki yaseNingizimu Afrika",
            "common": "Ningizimu Afrika"
          }
        }
      },
      "iso2": "ZA",
      "ccn3": "710",
      "cca3": "ZAF",
      "cioc": "RSA",
      "currencies": {
        "ZAR": {
          "name": "South African rand",
          "symbol": "R"
        }
      },
      "capital": [
        "Pretoria",
        "Bloemfontein",
        "Cape Town"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "languages": {
        "afr": "Afrikaans",
        "eng": "English",
        "nbl": "Southern Ndebele",
        "nso": "Northern Sotho",
        "sot": "Southern Sotho",
        "ssw": "Swazi",
        "tsn": "Tswana",
        "tso": "Tsonga",
        "ven": "Venda",
        "xho": "Xhosa",
        "zul": "Zulu"
      },
      "latlng": [
        -29,
        24
      ],
      "flag": "🇿🇦",
      "area": 1221037
    },
    {
      "name": {
        "common": "Zambia",
        "official": "Republic of Zambia",
        "native": {
          "eng": {
            "official": "Republic of Zambia",
            "common": "Zambia"
          }
        }
      },
      "iso2": "ZM",
      "ccn3": "894",
      "cca3": "ZMB",
      "cioc": "ZAM",
      "currencies": {
        "ZMW": {
          "name": "Zambian kwacha",
          "symbol": "ZK"
        }
      },
      "capital": [
        "Lusaka"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "eng": "English"
      },
      "latlng": [
        -15,
        30
      ],
      "flag": "🇿🇲",
      "area": 752612
    },
    {
      "name": {
        "common": "Zimbabwe",
        "official": "Republic of Zimbabwe",
        "native": {
          "bwg": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "eng": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "kck": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "khi": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "ndc": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "nde": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "nya": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "sna": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "sot": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "toi": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "tsn": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "tso": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "ven": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "xho": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          },
          "zib": {
            "official": "Republic of Zimbabwe",
            "common": "Zimbabwe"
          }
        }
      },
      "iso2": "ZW",
      "ccn3": "716",
      "cca3": "ZWE",
      "cioc": "ZIM",
      "currencies": {
        "BWP": {
          "name": "Botswana pula",
          "symbol": "P"
        },
        "CNY": {
          "name": "Chinese yuan",
          "symbol": "¥"
        },
        "EUR": {
          "name": "Euro",
          "symbol": "€"
        },
        "GBP": {
          "name": "British pound",
          "symbol": "£"
        },
        "INR": {
          "name": "Indian rupee",
          "symbol": "₹"
        },
        "JPY": {
          "name": "Japanese yen",
          "symbol": "¥"
        },
        "USD": {
          "name": "United States dollar",
          "symbol": "$"
        },
        "ZAR": {
          "name": "South African rand",
          "symbol": "Rs"
        },
        "ZWB": {
          "name": "Zimbabwean bonds",
          "symbol": "$"
        }
      },
      "capital": [
        "Harare"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "languages": {
        "bwg": "Chibarwe",
        "eng": "English",
        "kck": "Kalanga",
        "khi": "Khoisan",
        "ndc": "Ndau",
        "nde": "Northern Ndebele",
        "nya": "Chewa",
        "sna": "Shona",
        "sot": "Sotho",
        "toi": "Tonga",
        "tsn": "Tswana",
        "tso": "Tsonga",
        "ven": "Venda",
        "xho": "Xhosa",
        "zib": "Zimbabwean Sign Language"
      },
      "latlng": [
        -20,
        30
      ],
      "flag": "🇿🇼",
      "area": 390757
    }
  ];
  
  return allCountries;
  // jshint ignore: end
}

/**
 * Created by john on 23/9/16.
 */
'use strict';

/* exported
 citiesCtrl
  */

function citiesCtrl($scope, cities, appConfig, $location) {
  $scope.cities = cities;
  $scope.filters = {};

  $scope.getCityCoverStyle = function(city) {
    if (city.pictures.length) {
      var coverUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + city.pictures[0].url + '?width=300';
      return {'background-image': 'url("' + coverUrl + '")'};
    }
  };



  var search = $location.search();

  // On init if name is filtered
  if (search.name) {
    $scope.filters.name = search.name;
  }

  /**
   * Paging
   */
  // On init if page is above 1
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.cities.meta.total;
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;
    $scope.filters.populateProvince = true;

    $scope.cities.getList($scope.filters).then(function(cities) {
      $scope.cities = cities;
    });
  };

  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  /**
   * Filters
   */
  $scope.$on('filters.country', function(event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.populateProvince = true;

    $scope.cities.getList($scope.filters).then(function(cities) {

      $scope.currentPage = 1;
      $scope.cities = cities;
      $scope.totalItems = $scope.cities.meta.total;
    });
  });

  $scope.$on('filters.name', function(event, data) {
    $scope.filters.name = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.populateProvince = true;

    $scope.cities.getList($scope.filters).then(function(cities) {

      $scope.currentPage = 1;
      $scope.cities = cities;
      $scope.totalItems = $scope.cities.meta.total;
    });
  });
}

/**
 * Created by john on 2/8/17.
 */
'use strict';

/* exported
  menuCitiesCtrl
 */
function menuCitiesCtrl($scope, $rootScope, $location) {
  $scope.filters = {};

  // On init if country is selected display it
  var search = $location.search();

  if (search.country) {
    $scope.filters.country = {iso2: search.country};
  }

  if (search.name) {
    $scope.filters.name = search.name;
  }

  $scope.$watch('filters.country', function(country) {
    $rootScope.$broadcast('filters.country', ((country || {}).iso2 || '').toLowerCase());

    var search = $location.search();
    search.country = ((country || {}).iso2 || '').toLowerCase();
    $location.search(search);
  });

  $scope.$watch('filters.name', function(name) {
    $rootScope.$broadcast('filters.name', name);

    var search = $location.search();
    search.name = name;
    $location.search(search);
  });

  $scope.reset = function() {
    $scope.filters = {};
    $rootScope.$broadcast('filters.country', null);
    $location.search({});
  };
}

/**
 * Created by john on 2/8/17.
 */
'use strict';

/* exported
 cityViewCtrl
 */

function cityViewCtrl($scope, city, appConfig) {
  $scope.city = city;

  $scope.getCityCoverStyle = function() {
    if (city.pictures.length) {
      var coverUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + city.pictures[0].url + '?height=300';
      return {'background-image': 'url("' + coverUrl + '")'};
    }
  };

  var nearbyCityNames = [];
  if( city.nearbyCities) {
    for(var i = 0; i < city.nearbyCities.length; i++) {
      nearbyCityNames.push(city.nearbyCities[i].name);
    };
  }
  $scope.nearbyCityNames = nearbyCityNames.join(', ');
  
  $scope.getThumbnailStyle = function(image) {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + image.url + '?width=600';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };
}

/**
 * Created by john on 3/8/17.
 */
'use strict';

/* exported
 cityEditCtrl
 */

function cityEditCtrl($scope, city, countries, provinces, Restangular, $state) {
  $scope.city = city || {};
  $scope.selected = $scope.city._id ? { country: { iso2: $scope.city.countryCode.toLowerCase() } } : {};
  $scope.provinces = provinces;
  $scope.filteredProvinces = provinces;
  $scope.countries = countries;
  $scope.selectedCity = { _id: "3435" };

  $scope.city.nearbyCities = $scope.city.nearbyCities || [];

  $scope.selectedCity = null;
  $scope.lastCity = null;
  $scope.addNearbyCity = function () {
    if (!$scope.selectedCity.name || $scope.selectedCity.name === '') {
      return;
    }
    $scope.city.nearbyCities.push($scope.selectedCity);
    $scope.selectedCity = null;
  };

  $scope.removeNearbyCity = function (index) {
    $scope.city.nearbyCities.splice(index, 1);
  };

  $scope.$watch('selectedCity', function (value, oldValue) {
    if (value) {
      $scope.lastCity = value;
    }
  }, true);

  $scope.updateCountry = function (country) {
    if (country) {
      $scope.city.country = country.name.common;
      $scope.city.region = country.region;
      $scope.city.countryCode = country.iso2.toLocaleUpperCase();
    } else {
      $scope.city.country = '';
      $scope.city.region = '';
      $scope.city.countryCode = '';
    }
  };
  const refreshProvinces = (search) => {
    $scope.filteredProvinces = $scope.provinces.filter(function (province) {
      return province.name && province.name.toLowerCase().includes(search.toLowerCase());
    })
  };
  $scope.refreshProvinces = _.debounce(refreshProvinces, 200);
  $scope.populateProvinces = function () {
    $scope.city.province = $scope.provinces.find(function (p) { return p.slug === $scope.city.province });
  }

  $scope.populateProvinces();
  $scope.save = function () {
    $scope.globalError = null;

    if ($scope.cityForm.$invalid) {
      $scope.cityForm.name.$setTouched();
      $scope.cityForm.timezone.$setTouched();
      $scope.cityForm.country.$setTouched();

      return;
    }
    $scope.city.province = $scope.city.province ? $scope.city.province.slug : null;
    var nearbyCityIds = [...$scope.city.nearbyCities, ...($scope.lastCity ? [$scope.lastCity] : [])].map(function (city) {
      return city._id;
    });
    $scope.city.nearbyCities = nearbyCityIds;
    $scope.selectedCity = null;
    var promise = $scope.city._id ? $scope.city.put() : Restangular.all('cities').post($scope.city);

    promise
      .then(function (city) {
        $scope.$emit('notify', { type: 'success', title: 'Create city', message: 'City created with Success' });
        $state.go('root.editCity', { cityId: city._id }, { reload: true });
      })
      .catch(function (err) {
        $scope.$emit('notify', { type: 'error', title: 'Create city', message: 'Failed to create city' });

        $scope.globalError = err.data.message;
      });

  };
}

'use strict';

/*
exported
citiesSelectorDirective
 */

function citiesSelectorDirective(Restangular) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    templateUrl: 'views/cities/cities-selector.directive.html',
    scope: {
      ngModel: '=ngModel',
      countryCode: '=countryCode',
      from: '=from',
      label: '@label',
      placeholder: '@placeholder',
    },
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      // Hack for ui-select to update model
      scope.selected = {};

      scope.placeholder = scope.placeholder || 'Select city...';
      var initialFilter = {};
      initialFilter.populateProvince = true;

      if (scope.countryCode) {
        initialFilter.country = scope.countryCode;
      }

      if (scope.from) {
        initialFilter.from = scope.from;
      }

      Restangular
        .all('cities')
        .getList(initialFilter)
        .then(function(cities) {
          scope.cities = cities;
        });

      // End of init

      scope.$watch('countryCode', function(countryCode) {
        if (!countryCode) {
          return;
        }

        Restangular
          .all('cities')
          .getList({country: countryCode})
          .then(function(cities) {
            scope.cities = cities;
          });
      });

      scope.$watch('from', function(from) {
        if (!from) {
          return;
        }

        Restangular
          .all('cities')
          .getList({from: from})
          .then(function(cities) {
            scope.cities = cities;
          });
      });

      scope.onOpenClose = function(isOpen) {
        if (!isOpen) {
          ngModelCtrl.$setTouched();
        }
      };

      ngModelCtrl.$validators.required = function(modelValue) {
        return !!(modelValue && modelValue._id) || modelValue === null;
      };

      // From model to view
      ngModelCtrl.$formatters.push(function(modelValue) {
        return {city: modelValue};
      });

      // From view to model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.city;
      });

      // Render view
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.city && !ngModelCtrl.$viewValue.name) {
          var city = ngModelCtrl.$viewValue.city;

          Restangular
            .one('cities', city._id)
            .get()
            .then(function(city) {
              scope.selected.city = city;
            })
            .catch(function() {
              scope.selected.city = null;
            });
        } else {
          scope.selected.city = ngModelCtrl.$viewValue.city;
        }
      };

      // Update model
      scope.updateCity = function(city) {
        ngModelCtrl.$setViewValue({city: city});
      };

      // Refresh cities list on search
      scope.refreshCitiesList = function(search) {
        var filter = {name: search};
        filter.populateProvince = true;

        if (scope.from) {
          filter.from = scope.from;
        }

        Restangular
          .all('cities')
          .getList(filter)
          .then(function(cities) {
            scope.cities = cities.sort(function(cityA, cityB) {
              var cityADistance = cityA.name.toLowerCase().indexOf(search.toLowerCase());
              var cityBDistance = cityB.name.toLowerCase().indexOf(search.toLowerCase());

              return cityADistance - cityBDistance;
            });
          });
      };
    }
  };
}

"use strict";

/*
exported countriesSelectorDirective
 */

function countriesSelectorDirective(Restangular) {
  return {
    restrict: "EA",
    require: "ngModel",
    templateUrl: "views/cities/countries-selector.directive.html",
    scope: {
      ngModel: "=ngModel",
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      scope.fullCountryList = [];
      scope.countries = [];
      Restangular.one("v2/countries")
        .get("")
        .then(function (countryList) {
          scope.countries = countryList;
          scope.fullCountryList = countryList;
        });
      // Hack for ui-select to update model
      scope.selected = {};

      // From model to view
      ngModelCtrl.$formatters.push(function (modelValue) {
        return { country: modelValue };
      });

      // From view to model
      ngModelCtrl.$parsers.push(function (viewValue) {
        return viewValue.country;
      });

      ngModelCtrl.$render = function () {
        if (
          ngModelCtrl.$viewValue.country &&
          !ngModelCtrl.$viewValue.country.name
        ) {
          Restangular.one("v2/countries")
          .get("")
          .then(function (countryList) {
            scope.countries = countryList;
            scope.fullCountryList = countryList;
            scope.selected.country = scope.fullCountryList.find(function (
              country
            ) {
              return (
                country.iso2.toLowerCase() ===
                ngModelCtrl.$viewValue.country.iso2.toLowerCase()
              );
            });
          });

        } else {
          scope.selected.country = ngModelCtrl.$viewValue.country;
        }
      };

      // Update model
      scope.updateCountry = function (country) {
        ngModelCtrl.$setViewValue({ country: country });
      };

      scope.refreshCountriesList = function (search) {
        if (!search) {
          return;
        }
        scope.countries = scope.fullCountryList.filter(function (country) {
          return country.name.common.match(new RegExp(search, "i"));
        });
      };
    },
  };
}
